import React from 'react'
import { FcSimCardChip } from 'react-icons/fc'
import { SiMastercard } from 'react-icons/si'

export const Card = ({ typeCard, numberCard = '0000000000000000', month, year, cardHolder }) => {

    
  
    return (
        <div className='card border bg-light rounded rounded-4 m-0 p-0' style={{ minHeight: '150px', minWidth: '300px', width: '350px' }}>
                    
            <div className='card-header mb-0 pb-0 rounded-top bg-light'>
                <h5 className='card-title'>{typeCard}</h5>
            </div>

            <div className='card-body m-0 mb-2 pt-0 pb-0'>
                <div className='d-flex justify-content-between'>
                    <FcSimCardChip className='fs-1 mb-1'/>
                </div>
                <div className='d-flex flex-grow-1 flex-fill '>
                    {
                        numberCard.length === 16 &&
                        numberCard.match(/.{1,4}/g).map( (item, index) => {
                            return <input key={index} type='text' className='form-control text-center mx-1' style={{ height: '30px' }} readOnly placeholder={item} />
                        })
                    }
                </div>
                <div className='d-flex mt-1' style={{ width: '40%' }}>
                    <div className="form-text mx-2">
                        <label className='mx-2'>Mes</label>
                        <input type='text' min={2} minLength={2} max={2} maxLength={2} className='form-control text-center mx-1' style={{ height: '30px' }} readOnly placeholder={month} />
                    </div>
                    <div className="form-text mx-2">
                        <label className='mx-2'>Año</label>
                        <input type='text' min={2} minLength={2} max={2} maxLength={2} className='form-control text-center mx-1' style={{ height: '30px' }} readOnly placeholder={year} />
                    </div>
                </div>
            </div>

            <div className='card-footer bg-light m-0 pt-0 pb-0 rounded-bottom '>
                <div className='d-flex justify-content-between'>
                    <input type='text' className='form-control border-0 bg-light' readOnly placeholder={cardHolder} />
                    <div className='chip'>
                        <SiMastercard className='fs-1'/>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
