import { useContext } from "react";
import { PaymentContext } from "../Context/PaymentContext";
import { ContentShowMore } from "../../Components";
import LOGO from "../../Assets/img/logoRopa.png";
import { MainPaypal } from "../Components";
import { Recibo } from "../../Components/Recibo";


export const ConfirmPurchase = ({}) => {
  const { payment } = useContext(PaymentContext);

  // metodo para pagar la compra en paypal
  const handlePay = () => {
    // aqui se debe implementar la logica para pagar la compra
  };

  // metodo para guardar la compra en la base de datos
  const handleSave = () => {
    // aqui se debe implementar la logica para guardar la compra
  };

  // metodo para enviar la confirmacion de la compra al correo del usuario
  const handleSendEmail = () => {
    // aqui se debe implementar la logica para enviar el correo
  };

  // metodo para confirmar la compra
  const handleConfirm = () => {
    handlePay();
    handleSave();
    handleSendEmail();
  };

    const makeFolioRecibo = () => {
        // aqui se debe implementar la logica para generar el folio del recibo, se debe generar un numero aleatorio de 8 digitos
        // y se debe concatenar con la fecha actual en formato dd-mm-yyyy y con la hora actual en formato hh:mm:ss
        // ejemplo: 12345678-12-12-2021-12:12:12

        let folio = Math.floor(Math.random() * 100000000);
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hour = date.getHours();
        let minutes = date.getMinutes();

        return `${folio}-${day}/${month}/${year}-${hour}-${minutes}`;
    }

    return (
        <div className="container ">
            <Recibo
                user={payment?.address?.name}
                address={payment?.address?.address}
                city={payment?.address?.city}
                state={payment?.address?.state}
                phone={payment?.address?.phone}
                instructions={payment?.address?.instructions}
                productsList={payment?.productsList}
                total={payment?.total}
                discount={payment?.discount}
                iva={payment?.iva}
                costo_envio={payment?.costo_envio}
            />

            {/* Pendiente*/}
            <div className="row card shadow-none">
            
                <div className='row mb-3'>
                    <div className="col-md-6">
                        <h2>
                            <span>
                                <img alt="" src={LOGO} height="100" />  Tordillo Vaquero{" "}
                            </span>
                        </h2>
                    </div>
                    
                    <div className="col-md-6 text-right">
                        <div className="card shadow-none p-0">
                        <div className="card-header">
                            <h6>
                                NIT: <i> N&uacute;mero de NIT </i>
                            </h6>
                            <h6>
                                AUTORIZACI&Oacute;N: <i>N&uacute;mero de Aut.</i>
                            </h6>
                        </div>
                        <div className="card-body">
                            <h6>
                                Número de recibo: <i>{makeFolioRecibo()}</i>
                            </h6>
                        </div>
                        </div>
                    </div>
                </div>

                <hr />

                <h1 style={{ textAlign: "center" }}>RECIBO DE COMPRA</h1>

                <div className="row">
                    <div className="col-xs-12">
                        <div className="card shadow-none p-0">
                            <div className="card-header">
                                <div>
                                    Fecha: 
                                    <i>
                                        { new Date().toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }
                                    </i>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-table w-100">
                                    <div className="d-table-row">
                                        <h6 className="d-table-cell"> Comprador: <i > {payment?.address?.name} </i> </h6>
                                        <h6 className="d-table-cell"> Dirección: <i > {payment?.address?.address} </i> </h6>
                                    </div>
                                    
                                    <div className="d-table-row">
                                        <h6 className="d-table-cell"> Ciudad: <i>{payment?.address?.city}</i> </h6>
                                        <h6 className="d-table-cell"> Estado: <i>{payment?.address?.state}</i> </h6>
                                    </div>

                                    <div className="d-table-row">
                                        <h6 className="d-table-cell"> Telefono: <i>{payment?.address?.phone}</i> </h6>
                                    </div>
                                </div>
                                <div className="mt-3 ">
                                    <h6 className="m-0"> Detalles: </h6>
                                    <ContentShowMore
                                        text={payment?.address?.instructions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <pre></pre>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }}>
                                <h4>Cantidad</h4>
                            </th>
                            <th style={{ textAlign: "center" }}>
                                <h4>Concepto</h4>
                            </th>
                            <th style={{ textAlign: "center" }}>
                                <h4>Precio unitario</h4>
                            </th>
                            <th style={{ textAlign: "center" }}>
                                <h4>Total</h4>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Aqui se debe implementar la logica para mostrar los productos de la compra */}
                        {
                            payment?.productsList?.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center" }}>{item.amount}</td>
                                    <td>
                                        <i > {item.title} </i> / <i > {item.color} </i> / <i > {item.size} </i>
                                    </td>
                                    <td className=" text-right ">{item.price}</td>
                                    <td className=" text-right ">{item.price}</td>
                                </tr>
                            ))
                        }
                                    
                        {/* <tr>
                            <td>&nbsp;</td>
                            <td>
                            <i > &nbsp; </i>
                            </td>
                            <td className="text-right">&nbsp;</td>
                            <td className="text-right ">&nbsp;</td>
                        </tr> */}

                        <tr>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}>
                                Costo de envio:
                            </td>
                            <td style={{ textAlign: "right" }}>
                                <i > ${payment.costo_envio ?? 0} </i>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}>
                                IVA:
                            </td>
                            <td style={{ textAlign: "right" }}>
                                <i > ${payment.iva ?? 0} </i>
                            </td>
                        </tr>  

                        { 
                            payment.discount > 0 && (
                                <tr>
                                    <td style={{ textAlign: "right" }}></td>
                                    <td style={{ textAlign: "right" }}></td>
                                    <td style={{ textAlign: "right" }}>
                                        Descuento:
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <i > ${payment.discount ?? 0} </i>
                                    </td>
                                </tr>
                            )
                        }

                        <tr>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}>
                                Total:
                            </td>
                            <td style={{ textAlign: "right" }}>
                                <i > ${payment.total} </i>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}></td>
                            <td style={{ textAlign: "right" }}>
                                Son: <i > Veintiocho mil quinientos </i> 00/100
                                bolivianos
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pre></pre>

                <div className="row">
                    <div className="col-xs-4">
                        <a href=" ">
                            <img alt="" src="" />
                        </a>
                    </div>
                    <div className="col-xs-8">
                        <div className="panel panel-info" style={{ textAlign: "right" }}>
                            <h6>
                                {" "}
                                "LA ALTERACI&Oacute;N, FALSIFICACI&Oacute;N O
                                COMERCIALIZACI&Oacute;N ILEGAL DE ESTE DOCUMENTO ESTA PENADO
                                POR LA LEY"
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' '>
                <div className='d-flex justify-content-center'>
                    {/* <button className={'btn-confirm-purchase '}>
                        <div className="svg-wrapper-1">
                            <div className="svg-wrapper">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bag-fill" viewBox="0 0 16 16">
                                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4z"/>
                                </svg>
                            </div>
                        </div>
                        <span>Comprar</span>
                    </button> */}
                    <div className="d-flex justify-content-center">
                        <div className="stripe">
                            {/* <MainStripe /> */}
                        </div>
                        <div className="mt-5 paypal">
                            <MainPaypal price={ payment.total } />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

// <div className='container'>
//     <div className='row w-100 flex-column justify-content-center text-center'>
//         <h2>Resumen de la compra.</h2>
//         <i> Recibo y confirmación de la compra </i>
//     </div>
//     <div className='row'>
//         <div className='col-12'>
//             <div>
//                 <h3>Detalle</h3>
//                 <ul>
//                     <li>Total por productos { payment?.totalProducts } </li>
//                     <li>Costo de envio ${ payment?.costo_envio } </li>
//                     <li>IVA ${ payment?.iva } </li>
//                     <li>Total (IVA incluido en caso de ser aplicable) ${payment?.total} </li>

//                 </ul>
//             </div>
//             <div>
//                 <h3>Dirección de envio</h3>
//                 <p>
//                     <span>
//                         {
//                             <div>
//                                 <p>Nombre: {payment?.address?.name}</p>
//                                 <p>Dirección: {payment?.address?.address}</p>
//                                 <p>Ciudad: {payment?.address?.city}</p>
//                                 <p>Estado: {payment?.address?.state}</p>
//                                 <p>Código postal: {payment?.address?.zipcode}</p>
//                                 <p>Teléfono: {payment?.address?.phone}</p>
//                                 <p>Actualizado: {payment?.address?.updated_at}</p>
//                             </div>

//                         }
//                     </span>
//                 </p>
//             </div>
//             <div>
//                 <h3>Información del pago</h3>
//                 <p>
//                     <span>
//                         {
//                             <div>
//                                 <p>Número de tarjeta: {payment?.methodPay?.card_number}</p>
//                                 <p>Nombre en la tarjeta: {payment?.methodPay?.card_holder}</p>
//                                 <p>Fecha de expiración: {payment?.methodPay?.month}/{payment?.methodPay?.year}</p>
//                                 <p>CVV: {payment?.methodPay?.cvv}</p>
//                                 <p>Actualizado: {payment?.methodPay?.updated_at}</p>
//                             </div>
//                         }
//                     </span>
//                 </p>
//             </div>
//         </div>
//         <div className=''>
//             <div className='d-flex '>
//                 <button> Realizar pedido</button>
//             </div>
//         </div>
//     </div>
// </div>
