import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../Auth'
import { jwtDecode } from 'jwt-decode'
import { requestGet } from '../../Helpers'
import { Card } from './Components/Card'

export const MyShopping = () => {

    const [shopping, setShopping] = useState([])

    useEffect(() => {
        try {
            const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));
            
            const decoded = jwtDecode(token)
            
            getAllShopping(decoded?.user_id)

        } catch (error) {
            console.log(error);
        }
    }, [])

    const getAllShopping = (user_id) => {
        requestGet(`sales/`, {"user": user_id})
            .then((data) => {
                setShopping(data || [])
            })
    }

    return (
        <div className='container'>

            <h1 className='text-center'>Mis compras</h1>

            <div className='row'>
                <div className='col-12 col-md-6'>
                    <Card
                        title={'Orden'}
                        date={'Fecha'}
                        price={'Total'}
                        status={'Estado'}
                        updated_at={'Actualizado'}
                    />
                </div>
                
                {
                    shopping?.results?.map((item) => {
                        console.log('item', item)
                        return (
                            <div className='col-12 col-md-6'>
                                <Card
                                    key={item.pk}
                                    id={item.pk}
                                    total={item.total}
                                    order_id={item.order}
                                    status={item.status}
                                    created_at={item.created_at}
                                    updated_at={item.updated_at}
                                />
                            </div>
                        )
                    } ) 
                }
            </div>
        
        </div>
    )
}
