import { useEffect, useMemo, useState } from 'react'
import { OffCanvas } from '../../OffCanvas/OffCanvas'
import ReactImageGallery from 'react-image-gallery'
import { BtnFav } from './BtnFav'
import { BtnAddCar } from './BtnAddCar'
import { Characteristics } from '../../Characteristics'
import { CiShoppingCart } from 'react-icons/ci'
import { requestGet } from '../../../Helpers'
import { SpinnerLoading } from '../../SpinnerLoading'
import IMGLOGO from '../../../Assets/img/logoRopa.png';


/**
 * Este componente es un botón que se encarga de mostrar las opciones de un producto.
 * @param {string} id
 * @returns {JSX.Element}
 * @example
 * <BtnOptionsCar id="product_id" />
 * 
 * @see https://www.npmjs.com/package/react-image-gallery
 */

export const BtnOptionsCar = ({id}) => {

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const [product, setProduct] = useState({})
    const [images, setImages] = useState([])

    // Realiza la solicitud de los datos del producto
    const fetchProductData = async () => {
        try {
            const data = await requestGet(`products/`, {"uuid": id});
            const result = data?.results[0];
            setProduct(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        try {
            // Solo realiza la solicitud si el offcanvas está abierto
            if (showOffcanvas) {
                fetchProductData();
            }
  
        } catch (error) {
            console.log(error);
        }

        // Limpia el estado cuando el offcanvas se cierra
        return () => {
            setShowOffcanvas(false)
        }
    }, [showOffcanvas, id])

    useMemo(() => {
        // Si el producto tiene imágenes, las muestra, de lo contrario muestra una imagen por defecto
        if (product?.images) {
            setImages( product.images.map(item => (
                { 
                    original: item.image, 
                    thumbnail: item.image 
                }
            )))
        } else {
            setImages(
                [
                    {
                      original: IMGLOGO,
                      thumbnail: IMGLOGO,
                    },
                ]
            )
        }
    }, [product])


    return (
      <OffCanvas
        iconMain={
            <span 
                className="color-cafe-oscuro w-100 d-flex m-auto align-items-center "
                style={{  }}
            >
                <CiShoppingCart size={'2rem'} className=''  />
            </span>
        }
        title={'Detalle del producto'}
        footer={''}
        onShow={() => setShowOffcanvas(true)} // Abre el OffCanvas y activa la solicitud
        onHide={() => setShowOffcanvas(false)} // Cierra el OffCanvas y resetea el estado
      >
        {
            product ? (
                <>
                    <div className='card-img'>
                        <ReactImageGallery
                            additionalClass=""
                            items={images} 
                            thumbnailPosition = 'bottom'
                            showBullets = {false}
                            showPlayButton = {false}

                        />
                        {/* <img src={product?.image} alt='' /> */}
                    </div>
                    <div className='col'>
                            <h2 className='text-center fs-4'>{product?.name}</h2>
                            <h3 className='text-end mt-3 mb-2'><i className=''>${product?.price}</i></h3>
                            <p className='mt-3'>{product?.description}</p>

                            <Characteristics category={product?.specific_category} />

                            <div className='d-flex justify-content-between'>
                                <BtnAddCar
                                    id={product?.uuid}
                                    title={product?.name}
                                    description={product?.description}
                                    price={product?.price}
                                    image={product?.image?.image}
                                    text_aditional='Agregar al carrito'
                                />
                                <BtnFav
                                    id={product?.uuid}
                                    title={product?.name}
                                    description={product?.description}
                                    price={product?.price}
                                    image={product?.image}
                                    text_aditional='Agregar a favoritos'
                                />
                            </div>
                    </div>
                </>
        ) : (
            <SpinnerLoading />
        )
        }
      </OffCanvas>
    )
}
