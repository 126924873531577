// Code: Auth hook to check if user is logged in
import { requestGet } from '../../Helpers';
import { useQuery } from '@tanstack/react-query';

/*
* This function is to check if the user is logged in or not by checking the token in the local storage, 
* if the token is valid, it will return the user data.
* @returns {Promise} - Return a promise with the response
*/

const fetchAuthStatus = async () => {
    const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));
  
    if (!token) {
      throw new Error('No token found');
    }
  
    const response = await requestGet(`login/`, {"token": token});
  
    if (typeof response === 'object') {
        const {name, email, is_superuser, is_staff, is_active} = response.user;
        return {
            logged: !!response,
            user: {
                nombres: name,
                correo: email,
                is_superuser,
                is_staff,
                is_active,
                token
            },
            token
        };
    } else {
        localStorage.removeItem('user_store_ecommerce');
        throw new Error('Invalid token response');
    }
};

/*
* This function is a custom hook to check if the user is logged in or not.
* @returns {Object} - Return an object with the logged status, user data, error and loading status
*/

export const useChekingAuth = () => {
    
    const { data, error, isLoading } = useQuery({
        queryKey: ['authStatus'],  // Cambiado a formato de objeto
        queryFn: fetchAuthStatus,  // Cambiado a formato de objeto
        staleTime: 5 * 24 * 60 * 60 * 1000, // 5 días en milisegundos
        refetchOnWindowFocus: false, // No refetch when window is focused
    });
    
    return {
        logged: data?.logged || false,
        user: data?.user || {},
        error,
        isLoading,
    };
}
