import { requestPost } from "../../../Helpers";

/**
 * Add a new item to order
 * @param {String} productId
 * @param {String} orderId
 * @returns {Promise}
 * 
 * @example
 * addOrderItem("product_id", "order_id").then((data) => console.log(data));
 */

export const addOrderItem = async (orderId, productCategory) => {

    const formData = new FormData();
    formData.append("order", orderId);
    formData.append("category_id", productCategory);

    return await requestPost("order_items/", formData).then((data) => {

      if ( typeof(data) == "object" ) {
        return data
      } else {
        return false
      }
    });

}


