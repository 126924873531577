import { useState, useEffect } from "react";
import { useRequestGet } from "../../Hooks";
import { useParams } from "react-router-dom";
import { CardReview } from "../Cards";


export const ReviewsByProduct = ({product_id}) => {
    
    const {id} = useParams();
    const [reviews, setReviews] = useState([]);
    const {data, loading, error} = useRequestGet(`reviews/`, {"uuid":id});
    
    useEffect(() => {
        if (data) {
            setReviews(()=> data?.results);
        }
    }, [data])

    return (
        <div className='row'>

            <div className=" col-8">
                {
                    reviews?.map( (value, index) => (
                        <div className='mb-4' key={index}>
                            <CardReview
                                key={value.id}
                                product={value.product_name}
                                user={value.user_name}
                                rating={value.rating}
                                comment={value.comment}
                            />
                        </div>
                    ) )
                }
            </div>
            <div className="col-4">
                <div className="">
                    <h4> Promedio: {reviews[0]?.cant_rating_product?.avg_rating.toFixed(2)} </h4>
                    <h4> Max: {reviews[0]?.cant_rating_product?.max_rating} </h4>
                    {/* <h4> Min: {reviews[0]?.cant_rating_product?.min_rating} </h4> */}
                </div>
            </div>
        </div>
    )

}
