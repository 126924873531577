import { useContext, useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { FilterSearch, SearchInput } from "./Components";
import { StoreContextMain } from "../../Context/StoreContextMain";
import queryString from 'query-string'
import { useLocation, useNavigate } from "react-router-dom";



export const SearchEngine = () => {

    const {search, setSearch} = useContext(StoreContextMain)

    const navigate = useNavigate();
    const location = useLocation();

    const {q = ''} = queryString.parse(location.search);


    useEffect( () => {

        if (q) {
            setSearch(q)
        }

    }, [q, setSearch] )

    const handleSearch = (e) => {
        e.preventDefault()
    
        if(search.trim().lenght <= 3) return;

        navigate(`/seccion_search/name?search=${search}`);
    }

    return (
        <form className="d-flex w-50 m-auto" role="search">
            <div className="input-group m-0 p-0 px-2 pe-1 w-100 border border-1 rounded rounded-5 ">
                
                <FilterSearch />

                <SearchInput
                    search={search}
                    setSearch={setSearch}
                />

                <button className="btn  border-none" onClick={handleSearch}>
                    <MdSearch className={"fs-3"} />
                </button>
            </div>
        </form>
    );
};
