import { requestPost } from "../../../Helpers";
import { alertError, alertToast } from "../../../Helpers/Alerts";

/**
 * Add a new item to wishlist
 * @param {String} productId
 * @param {String} wishlistId
 * @returns {Promise}
 * @example
 * addWishListItem("product_id", "wishlist_id").then((uuid) => console.log(uuid));
 */

export const addWishListItem = (productId, wishlistId) => {

    const formData = new FormData();
    formData.append("product", productId);
    formData.append("wishlist", wishlistId);
    console.log("productId", productId, "wishlistId", wishlistId);
    requestPost("wishlist_items/", formData).then((data) => {
      const datos = JSON.parse(data)
      // console.log(datos.product_id.uuid)
      if ( datos.product_id.uuid ) {
        alertToast('Se agrego a tu lista', 'success', 3000)
      } else {
        alertError('No se pudo guardar', '¡Hay un error, intente de nuevo por favor!')
      }
    });

  }