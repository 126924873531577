
import { useContext, useEffect, useState } from 'react'
import { Card } from './Components/Card';
import { requestGet } from '../../Helpers';
import { AuthContext } from '../../Auth';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';


export const PaymentMethod = () => {

    const navigate = useNavigate()

    const [myCards, setMyCards] = useState([])

    useEffect( () => {
        try{
            const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

            const decode = jwtDecode(token)

            handleGetCards(decode?.user_id)
        } catch (error) {
            console.log(error);
        }
    }, [])

    const handleGetCards = (user) => {
        requestGet(`cards/`, {"user": user})
            .then((data) => {
                setMyCards( data ?? [] )
            })
            .catch((error) => {
                console.log(error);
                setMyCards([])
            } )
    }

    const handleAddCard = () => {
        navigate('/profile/payment/add')
    }
  
    return (
      <div className='container'>
    
        <h2 className='text-center mt-2'>Métodos de pago</h2>

        <div className='row'>
            <div className='d-flex justify-content-end'>
                <button className='btn btn-primary' onClick={handleAddCard}>Agregar método de pago</button>
            </div>
        </div>

        <div className='row mt-3 justify-content-center'>
            {
                myCards?.length === 0 && (
                        <div className='d-flex flex-column'>
                            <br className='border-bottom border-2 ' />
                            <h3 className='text-center text-muted'>No tienes tarjetas registradas</h3>
                        </div>
                    ) 
            }
            {
                myCards?.results?.map((item, index) => (
                    <div className='col-12 col-sm-3 col-md-4 mb-4' key={index} >
                        <div>
                            <Card
                                typeCard = {item?.type_card}
                                numberCard = {item?.card_number}
                                month = {item?.month}
                                year = {item?.year}
                                cardHolder = {item?.card_holder}
                                cvv = {item?.cvv}
                            />
                        </div>
                    </div>
                ))
            }
        </div>

        <div className='row'>
            
        </div>

      </div>
    )
}
