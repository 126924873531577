import { requestPost } from "../../../Helpers";
import { alertError } from "../../../Helpers/Alerts";

/**
 * Delete an item from wishlist
 * @param {String} item_wishlist_product_id
 * @returns {Promise}
 * @example
 * deleteItem("item_wishlist_product_id").then((uuid) => console.log(uuid));
 */

export const deleteItem = (item_wishlist_product_id) => {

    if (!item_wishlist_product_id) {
        alertError("Falta información.", "Error al eliminar producto de la lista de deseos.");
        return;
    }

    const formData = new FormData();
    formData.append("product", item_wishlist_product_id);

    requestPost(`wishlist_items/delete_to_wishlistitem/`, formData)
        .then((data) => {
            console.log("data", JSON.parse(data));
            return JSON.parse(data)
        });
    
}