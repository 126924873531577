

export const Sizes = ({name, active, handleSizes}) => {

  return (
    <button
      className={`btn btn-${active === name ? '' : 'outline-' }secondary mx-1 mt-1`}
      onClick={handleSizes}
    >
      {name}
    </button>
  )
}
