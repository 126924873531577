import axios from "axios";
import { baseUrlServices } from "../shared/baseUrl";
import { validateUrl } from "./validateUrl";

export const requestGet = async (serviceName, params, enableToken = true) => {
    try {
        // Configuración de los headers
        const headers = {};

        // Agregar el token solo si enableToken es true
        if (enableToken) {
            const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));
            if (token) {
                headers.Authorization = `Bearer ${token}`;
            } else {
                console.warn("Token not found in localStorage");
            }
        }

        // Construir la URL completa
        const url = new URL(baseUrlServices.concat(serviceName));
        
        // Agregar los parámetros a la URL
        if (params) {
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        }
        
        // Validar la URL
        if (!validateUrl(url, params)) {
            console.error("Invalid URL or params", url, params);
            return;
        }

        // Realizar la petición con axios
        const responseGet = await axios.get(url, { headers });

        // Devolver los datos de la respuesta (Axios ya convierte automáticamente a JSON)
        return responseGet.data;

    } catch (error) {
        // Manejo de errores
        if (error.response) {
            // Errores de respuesta HTTP (4xx, 5xx)
            console.error(`Error ${error.response.status}: ${error.response}`);
            if (error.response.status === 400 && error.response.data.code === 'token_not_valid') {
                // Unauthorized: token inválido
                localStorage.removeItem('user_store_ecommerce');
                console.warn("Token removed from localStorage");
            }
            throw new Error(`Error ${error.response.status}: ${error.response.statusText}`);
        } else if (error.request) {
            // Verificamos si ya hemos recargado la página en esta sesión
            if (!sessionStorage.getItem('pageReloaded')) {
                sessionStorage.setItem('pageReloaded', 'true'); // Marcamos que ya se ha recargado
                window.reload(); // Recargamos la página
            }
            // Errores de red (sin respuesta del servidor)
            console.error("Network error: No response received");
            throw new Error("Network error: No response received");
        }
         else {
            // Otros errores (como errores de red)
            console.error("Fetch error:", error.message);
            throw error;
        }
    }
};
