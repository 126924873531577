import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React from 'react'
import { PayPalCustom } from './Components'


export const MainPaypal = ({price}) => {

    const initialOptions = {
        clientId: process.env.REACT_APP_CLIENT_ID_PAYPAL,
        currency: "MXN",
        intent: "capture",
        // dataClientToken: "ENWlUwSGDSWVzZlaYSEqEuAjvsONbErEp60OC43vrm9g_Dw_ZSyyXX42bR7bVwc8T1DUt3VJSFmwWOFW",

    }

  
    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalCustom price={price}/>
        </PayPalScriptProvider>
    )
}
