import React, { useEffect, useState } from 'react'
import { Banner } from '../Components'
import { Card } from '../Components'
import { useRequestGet } from '../Hooks';


export const BannersCards = ({filters = '', search = ''}) => {

    const url = `products/${filters || search}`;

    const {data} =  useRequestGet(url);
    const [products, setProducts] = useState([]);

    useEffect(() => {

        if (data && url !== 'products/'.trim()) {            
            const products = data;
            
            setProducts(products);
        } else {
            return setProducts([]);
        }

        return () => {
            setProducts([])
        }

    }, [data, url])

    return (
        <>
            {
                <Banner
                    className = "d-flex justify-content-center m-auto"
                    slidesPerView='auto'
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                    }}
                    contentArray={
                        products?.results?.map((product) => (
                            <Card
                                key={product.id}
                                id={product.uuid}
                                image={product.image?.image}
                                title={product.name}
                                description={product.description}
                                price={product.price}
                            />
                        ))
                    }
                />
            }
        </>
    )
}
