import { BtnFav, BtnOptionsCar } from "./Components";
import { NavLink } from "react-router-dom";
import IMGLOGO from '../../Assets/img/logoRopa.png';

export const Card = ({ id = null, title = "title 1", description = "", discount = 0, price = 0.00, image }) => {

  const calculateDiscount = () => {
    return Number(price) -  Number(discount)
  }

  return (
    <section className="card" style={styles.card_wrap}>
      <NavLink
        to={`/view-product/${id}`}
        className="text-decoration-none text-black h-100"
      >
        <div className="card-img product-image ">
          <img
            className="m-auto d-flex img-fluid h-100"
            style={{ maxHeight: "230px", objectFit: "contain", width: "100%" }}
            src={image || IMGLOGO}
            alt="Producto"
            draggable="false"
          />
        </div>
        <div className="card-body">
          <h2 className="p-0 m-0 text-center text-muted fs-6">{ title.slice(0, 15) } {title.length > 15 ? "..." : ""}</h2>
          <p className="p-0 m-0 text-center text-muted text-decoration-line-through fs-6">
            {
              discount > 0 && (<small>${price}</small>)
            }
          </p>
          <div className="price text-center fs-4">${calculateDiscount()}</div>
        </div>
      </NavLink>
      <div className="card-footer" style={{  }}>
        <div className="d-flex justify-content-around">
          <BtnOptionsCar
            id={id}
          />
          <BtnFav
            id={id}
            title={title}
            description={description}
            price={price}
            image={image}
          />
          {/* <BtnAddCar
            id={id}
            title={title}
            description={description}
            price={price}
            image={image}
          /> */}
        </div>
      </div>
    </section>
  );
};


const styles = {
  card_wrap: {
    'display': 'flex',
    // flexWrap: 'wrap',
    boxShadow: 'none'

  },
}