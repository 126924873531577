import { requestGet } from "../../../Helpers"

/**
 * Get the wishlist of a user
 * @param {String} id
 * @returns {Promise}
 * @example
 * getWishList("user_id").then((uuid) => console.log(uuid));
 */

export const getWishList = async (id) => {
    
    return await requestGet(`wishlists/`, {"user": id})
        .then((data) => {
            return data
        });
    
}
