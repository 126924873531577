import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "./Context/StoreProvider";
import { AuthProvider } from "./Auth";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";


import App from "./App";


import "swiper/css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-image-gallery/styles/css/image-gallery.css";

import "./Assets/Styles/ColorPalette.css";
import "./Assets/Styles/index.css";
import "./Assets/Styles/App.css";
import './Assets/Styles/payment.css';
import './Assets/Styles/whatsap.css';
import './Assets/Styles/customButtons.css';
import './Assets/Styles/celebrationAnimation.css';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE}>
        <AuthProvider>
          <BrowserRouter>
            <StoreProvider>
              <App />
            </StoreProvider>
          </BrowserRouter>
        </AuthProvider>
      </GoogleOAuthProvider>;
    </QueryClientProvider>
  </React.StrictMode>
);
