import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrlServices } from "../shared/";
import { validateUrl } from "../Helpers/validateUrl";

export const useRequestGet = (serviceName = '', params = {}, refresh = false) => {
    
    const [data, setData] = useState({
        isLoading: false,
        isError: false,
        data: null
    });

    useEffect(() => {

        // juntar los parámetros en la URL
        const url = new URL(baseUrlServices.concat(serviceName));
        
        // Agregar los parámetros a la URL
        if (params) {
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        }

        // Validar la URL
        if (!validateUrl(url, params)) {
            console.error('URL is not valid');
            return;
        }
        
        // Configuración de los headers
        const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

        setData({
            isLoading: true,
            isError: false,
            data: null
        });

        axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        .then(response => {
            setData({
                isLoading: false,
                isError: false,
                data: response.data
            });
        })
        .catch(error => {
            console.log(error);
            setData({
                isLoading: false,
                isError: true,
                data: null
            });
        });

        return () => {
            setData({
                isLoading: false,
                isError: false,
                data: null
            });
        }

    }, [serviceName, refresh]);

    return data;
};
