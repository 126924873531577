import React from "react";
import { GiShoppingBag } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

export const Card = ({ id, total, order_id, created_at, status, updated_at }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/profile/my_shopping/view/${order_id}/${id}`);
  };

  return (
    <div className="card w-100 pt-1 pb-1">
      <div className="row g-0 m-0 p-0">
        <div className="col-md-4">
          <div className="d-flex m-auto h-100 w-100 justify-content-center align-items-center">
            <GiShoppingBag
              className=" img-fluid rounded-start"
              style={{ fontSize: "3rem" }}
              alt="..."
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="card-body m-0 p-1">
            <h5 className="card-title"> {order_id} </h5>
            <div className="d-flex justify-content-around">
              <p className="card-text"> {created_at} </p>
              <p className="card-text">{total}</p>
              <p className="card-text">{status}</p>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-sm btn-link"
                onClick={handleViewDetails}
              >
                Ver detalles
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <small className="text-muted">
          Última actualización hace {updated_at}{" "}
        </small>
      </div>
    </div>
  );
};
