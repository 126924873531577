import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Pagination = ({ totalItems, itemsByRequest, setPrevious, setNext }) => {

  const navigate = useNavigate();
  const [count, setCount] = useState(1);

  const handlePrevious = () => {
    if (count <= 1) {
      setCount(1);
      setPrevious(1);
      navigate(`?currentpage=1`);
    } else {
      setCount(count - 1);
      setPrevious(count - 1);
      navigate(`?currentpage=${count - 1}`);
    }
  };

  const handleNext = () => {
    if ( count >= (totalItems / itemsByRequest) ) {
      setCount(totalItems / itemsByRequest);
      setNext(totalItems / itemsByRequest);
      navigate(`?currentpage=${(totalItems / itemsByRequest)}`);
    } else {
      setCount(count + 1);
      setNext(count + 1);
      navigate(`?currentpage=${count + 1}`);
    }
  };

  let html = [];
  let pages = Math.ceil(totalItems / itemsByRequest);

  for (let i = 1; i <= pages; i++) {
    html.push(
      <li className="page-item" key={i}>
        <button className="page-link" onClick={() => setNext(i)}>
          {i}
        </button>
      </li>
    );
  }

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Previous"
            onClick={() => handlePrevious()}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {html}
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Next"
            onClick={() => handleNext()}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};
