import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BtnGoBack } from '../Components';
import { useForm } from '../../Hooks/useForm';
import { AuthContext } from '../../Auth';
import { jwtDecode } from 'jwt-decode';
import { requestGet } from '../../Helpers';
import { CardAddress } from '../../Profile/Address/Components/CardAddress';
import { PaymentContext } from '../Context/PaymentContext';
import { alertError } from '../../Helpers/Alerts';
import { NotFound } from '../../Components/NotFound';

export const PersonalInformation = () => {

    const { payment, setPayment } = useContext(PaymentContext);

    const [myCards, setMyCards] = useState([]);

    const [selectedCard, setSelectedCard] = useState(null);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      try {
        const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

        const decode = jwtDecode(token);
  
        handleGetCards(decode?.user_id);

      } catch (error) {
        console.log(error);
      }
    }, []);
  
    const handleGetCards = (user) => {
      requestGet(`shipping_addresses/`, {"user": user})
        .then((data) => {
            console.log(data);
            setMyCards(data ?? []);
        })
        .catch((error) => {
          console.log(error);
          setMyCards([]);
        });
    };

    const handleContinue = () => {
        if (!selectedCard) return alertError('Debes seleccionar una dirección de envío');
        navigate('/payment/purchase-detail');
    }

    const handleSelectedCard = (card) => {
        if (selectedCard === card) {
            setSelectedCard(null);
            setPayment({
                ...payment,
                address: null
            });
        } else {
            setSelectedCard(card);
            setPayment({
                ...payment,
                address: card
            });
        }
    }

    return (
        <div className='container mt-3'>
            <div className='row justify-content-center'>
                <div className='container'>
                    <div className='card shadow-none p-0'>
                        <div className='card-header'>
                            <h5 className='card-title'>Direcciones de envío</h5>
                        </div>

                        <div className="card-body ">
                            <div className='row '>
                            {
                                myCards?.results?.length === 0 && (
                                    <div className='col m-4'>
                                        <NotFound 
                                            title='No tienes direcciones de envío'
                                            message='Ir a agregar dirección de envío'
                                            navigate={true}
                                            path='/profile/address/add'
                                            navigateText='Agregar dirección'
                                        />
                                    </div>
                                )
                            }
                            {
                                myCards?.results?.map((item, index) => {
                                    return (
                                        <div className='col' key={index}>
                                            <div className="form-check" key={item.id}>
                                                <input
                                                    className="form-check-input position-absolute m-2"
                                                    style={{ width: '20px', height: '20px' }}
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onClick={() => handleSelectedCard(item)}
                                                    checked={selectedCard?.id === item.id}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    <CardAddress
                                                        key={item.id}
                                                        name={item.name}
                                                        address={item.address}
                                                        city={item.city}
                                                        state={item.state}
                                                        zipcode={item.zipcode}
                                                        phone={item.phone}
                                                        updated_at={item.updated_at}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            </div>
                        </div>

                        <div className='d-flex justify-content-end p-3'>
                            <BtnGoBack />
                            &nbsp;
                            <button className="btn btn-primary">Guardar</button>
                            &nbsp;                           
                            <button className='btn btn-primary' onClick={handleContinue}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
