import React, { useEffect, useState } from 'react'
import { useRequestGet } from '../Hooks';
import { NavLink } from 'react-router-dom';


export const IconsRedirect = () => {

    const {data, isLoading, isError} =  useRequestGet('banners/', {"tipo":"IR"});

    const [banners, setBanners] = useState([]);
  
    useEffect(() => {
      try {
        if (data) {
          setBanners( () => data?.results )
        }
      } catch (error) {
        console.log(error);
      }
    }, [data])
  

    return (
        <div className='row '>
        {
            banners?.map( (banner) => (
                <NavLink
                    to={banner.url}
                    key={banner.uuid}
                    className='col'
                >
                    <div style={styles.circle}>
                        <img src={banner?.image} alt={banner?.description} className='img-fluid' />
                    </div>
                </NavLink>

            ))
        }
        </div>
    )
}

const styles = {
    circle: {
        display: 'block',
        borderRadius: '50%',
        width: '100px',
        height: '100px',
        // backgroundColor: 'red',
        margin: '0 auto',
        textAlign: 'center',
        lineHeight: '100px'

    },
    icon: {
        fontSize: '3rem',
        color: 'white'
    }
}
