import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../../Auth";
import { requestGet } from "../../Helpers";
import { Card } from "../../Profile/MyPaymentMethods/Components/Card";
import { BtnGoBack } from "../Components";
import { PaymentContext } from "../Context/PaymentContext";
import { alertError } from "../../Helpers/Alerts";
import { NotFound } from "../../Components/NotFound";


export const PurchaseDetail = () => {
  
  const { payment, setPayment } = useContext(PaymentContext);

  const [myCards, setMyCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

      const decode = jwtDecode(token);

      handleGetCards(decode?.user_id);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleGetCards = (user) => {
    requestGet(`cards/`, {"user": user})
      .then((data) => {
        setMyCards(data ?? []);
      })
      .catch((error) => {
        setMyCards([]);
      });
  };

  const handleContinue = () => {
    if (!selectedCard) {
      return alertError("Selecciona un método de pago");
    }
    navigate("/payment/confirm-purchase");
  };

  const handleSelectedCard = (card) => {
    if (selectedCard === card) {
      setSelectedCard(null);
      setPayment({
        ...payment,
        methodPay: null,
      });
    } else {
      setSelectedCard(card);
      setPayment({
        ...payment,
        methodPay: card,
      });
    }
  }

  return (
    <div className="container mt-3">
      <div className="card shadow-none p-0">
        <div className="card-header">
          <h5 className="card-title">Método de pago</h5>
        </div>
        <div className="row mt-3 p-3">
          <div className="col-12 col-md-9">
            {
                myCards?.results?.length === 0 && (
                    <div className='col m-4'>
                      <NotFound 
                        title='No tienes métodos de pago'
                        subtitle='Agrega un método de pago para continuar con tu compra'
                        navigate={true}
                        path='/profile/payment/add'
                        navigateText="Agregar método de pago"
                      />
                    </div>
                )
            }
            {myCards?.results?.map((item) => {
              return (
                <div className="form-check" key={item.id}>
                  <input
                    className="form-check-input position-absolute "
                    style={{ zIndex: 1, cursor: "pointer", width: "20px", height: "20px", marginLeft: "20rem", marginTop: "10px" }}
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onClick={() => handleSelectedCard(item)}
                    checked={selectedCard?.id === item.id}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    <Card
                      key={item.id}
                      typeCard={item.type_card}
                      numberCard={item.card_number}
                      nameCard={item.card_holder}
                      month={item.month}
                      year={item.year}
                      cvvCard={item.cvv}
                    />
                  </label>
                </div>
              );
            })}
          </div>
          <div className="col-12 col-md-3">
            <div className="card shadow-none p-0 h-100">
              <div className="card-header">
                <h5 className="card-title">Opciones de pago</h5>
              </div>
              <div className="card-body">
                {
                  ['paypal', 'tarjeta', 'oxxo'].map((item, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        {item}
                      </label>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="col-12">
            <br />
            <div className="d-flex justify-content-end">
              <BtnGoBack />
              &nbsp;
              <button className="btn btn-primary">Guardar</button>
              &nbsp;
              <button onClick={handleContinue} className="btn btn-success">
                Proceder a pagar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
