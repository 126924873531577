import React from 'react'
import { Rating } from './Components/Rating'

export const CardReview = ({product, user, rating, comment}) => {

    return (
        <div className='card ' style={{ boxShadow: 'none' }}>
            <div className='card-header'>
                <div className='d-flex justify-content-between'>
                    <h6>{product}</h6>
                    <Rating rating={rating} />
                </div>
            </div>
            <div className='card-body'>
                <p className='card-text'>{comment}</p>
            </div>

            <div className='card-footer'>
                <p className='text-end card-text'>{user}</p>
            </div>
        </div>
    )
}
