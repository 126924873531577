import React from 'react'
import { CardAdvertisement } from '../Components'

import { FaBox } from "react-icons/fa";
import { GrSecure } from "react-icons/gr";
import { FaUndo } from "react-icons/fa";


export const MultiColumnAdversiment = () => {
  
    return (
        <div className='multiAdversiment row row-cols-1 row-cols-sm-2 row-cols-md-3 gap-2 justify-content-around'>
            <CardAdvertisement 
                icon={<>
                    <FaBox className='fs-3' />
                </>} 
                title={'Envios a todo México'} 
                text={'Los envios son seguros y gratis a partir de $0,000'}
            />

            <CardAdvertisement 
                icon={<>
                    <GrSecure className='fs-3' />
                </>} 
                title={'Pagos seguros'} 
                text={'Los pagos son a través del sistema de paypal'}
            />

            <CardAdvertisement 
                icon={<>
                    <FaUndo className='fs-3' />
                </>} 
                title={'Cambios y devoluciones'} 
                text={'Los envios son seguros y gratis a partir de $0,000'}
            />
        </div>
    )
}
