import {useState} from 'react'
import { PaymentContext } from './PaymentContext'

export const PaymentProvider = ({children}) => {
    
    const [payment, setPayment] = useState({
        total: 0,
        iva: 0,
        totalProducts: 0,
        productsList: []
    })
    
  
    return (
        <PaymentContext.Provider 
            value={{
                payment,
                setPayment
             }}
        >
            {children}
        </PaymentContext.Provider>
    )
}
