import { useState, useEffect } from "react"
import { FaStar } from "react-icons/fa";



export const Rating = ({rating}) => {

    const [stars, setStars] = useState([]);

    useEffect(() => {
        let arr = [];
        for (let i = 0; i < rating; i++) {
            arr.push(i);
        }
        setStars(arr);
    }
    , [rating])

  
    return (
        <div className='d-flex'>
            {
                stars.map( (value, index) => (
                    <FaStar key={index} size={'1.5rem'} color={'gold'} />
                ))
            }
        </div>
    )
}
