import React, { useContext, useEffect } from "react";
import { StoreContextMain } from "../../Context/StoreContextMain";
import { CardOrder } from "../../Components/Cards/CardOrder";
import { useNavigate } from "react-router-dom";
import { CardDetailPayment } from "../Components";
import { PaymentContext } from "../Context/PaymentContext";

export const DetailsPayment = () => {
  const { store } = useContext(StoreContextMain);
  const { payment, setPayment } = useContext(PaymentContext);

  const navigate = useNavigate();

  console.log(store);

  const handleContinue = () => {
    navigate("/payment/personal-information");
  };

  const getSum = (total, item) => {
    item = Number(item.price)
    return total + item
  }

  useEffect(() => {
    
    if (store) {
      setPayment({
        total: store.reduce(getSum, 0),
        iva: 10,
        totalProducts: store.length,
        productsList: store
      });
    }
  
  }, [store, setPayment]);
  

  return (
    <div className="container mt-3" style={{ background: 'transparent' }}>
      <div className="card shadow-none p-0" style={{ boxShadow: 'none' }}>
        <div className="card-header">
          <h5 className="card-title">Detalles de la compra</h5>
        </div>
        <div className="mt-4 row p-3">
          <div className="col-md-6">
            {
              store?.map((item, index) => (
                <CardOrder
                  key={index}
                  id={item.id}
                  title={item.title}
                  image={item.image}
                  price={item.price}
                  color={item.color}
                  size={item.size}
                  amount={item.amount}
                  amount_max={item.amount_max}
                  enableFeacture={true}
                />
              ))
            }
          </div>
          <div className="col-md-6">
            <CardDetailPayment
              title="Detalles de la compra"
              totalProducts={store?.length}
              iva={10}
              total={store.reduce(getSum, 0)}
              productsList={store}
            />
          </div>
          <div className="d-flex flex-row justify-content-end mt-3">
            
            <button className="btn btn-primary" onClick={handleContinue}>
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
