// CelebrationAnimation.jsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Función para generar colores aleatorios
const getRandomColor = () => {
  const colors = ['#f94144', '#f3722c', '#f8961e', '#90be6d', '#577590'];
  return colors[Math.floor(Math.random() * colors.length)];
};

export const CelebrationAnimation = ({ isVisible = true, message = '', action = () => {} }) => {

  // Generamos un array con elementos para cada partícula de confeti
  const confettiPieces = Array.from({ length: 100 });

  return (
    <>
      {isVisible && (
        <div className="confetti-container">
          <div className='d-flex flex-row'>
            {/* Mostramos un mensaje si se proporciona */}
            {message && (
              <motion.div
                className="confetti-message"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{ duration: 0.5 }}
              >
                {message}
              </motion.div>
            )}

            {/* Mostramos un boton de okey */}
            <motion.div
              className="confetti-button"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.5 }}
            >
              <button 
                className="btn w-25 btn-primary" 
                onClick={action}
              >
                Coninuar
              </button>
            </motion.div>
          </div>

          {/* Creamos una partícula de confeti por cada elemento del array */}
          {confettiPieces.map((_, index) => (
            <motion.div
              key={index}
              className="confetti-piece"
              initial={{
                x: Math.random() * window.innerWidth, // Posición horizontal aleatoria
                y: -40, // Empieza fuera de la pantalla (arriba)
                rotate: 0,
              }}
              animate={{
                y: window.innerHeight + 50, // Se mueve hacia abajo fuera de la pantalla
                rotate: [0, 360], // Gira mientras cae
              }}
              transition={{
                duration: 2 + Math.random() * 2, // Duración aleatoria entre 2 y 4 segundos
                ease: 'easeInOut',
                repeat: Infinity, // Repetición infinita
                delay: Math.random() * 1, // Retraso inicial aleatorio
              }}
              style={{
                backgroundColor: getRandomColor(), // Color aleatorio para cada pieza
                left: `${Math.random() * 100}%`, // Posición inicial aleatoria
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};

