import React from 'react';
import { useNavigate } from 'react-router-dom';
import { requestPost } from '../../../Helpers';


export const CardAddress = ({name, address, city, state, zipcode, phone, updated_at, ruta=false, deleteAddres=false}) => {

    const navigate = useNavigate();

    const handleUpdate = (ruta) => {
        if (!ruta) {
            return;
        }
        navigate(ruta)
    }

    const handleDelete = () => {

        // requestPost('shipping_addresses/?id')
        //     .then( ()=> {

        //     })
        //     .catch( ()=> {

        //     })
    }
  
    return (
        <div className='card shadow-none '>
            <div className='card-body'>
                <h5 className='card-title'>Dirección de envío</h5>
                <p className='card-text'>Nombre: {name}</p>
                <p className='card-text'>Dirección: {address}</p>
                <p className='card-text'>Ciudad: {city}</p>
                <p className='card-text'>Estado: {state}</p>
                <p className='card-text'>Código postal: {zipcode}</p>
                {/* <p className='card-text'>País: {item?.country}</p> */}
                <p className='card-text'>Teléfono: {phone}</p>
                <div className='d-flex justify-content-around'>
                    {
                        ruta && (<button onClick={()=>handleUpdate(ruta)} className='btn btn-primary'>Editar</button>)
                    }
                    {
                        deleteAddres && (<button onClick={()=>handleDelete} className='btn btn-danger'>Eliminar</button>)
                    }
                </div>
            </div>
            <div className='card-footer'>
                <small className='text-muted'>Última actualización fue {updated_at}</small>
            </div>
        </div>
    )
}
