import React, { useContext } from 'react'

export const SearchInput = ({search, setSearch}) => {

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    return (
        <>
            <input
                className="form-control"
                style={{ 
                    border: 'none', 
                    outline: 'none', 
                    boxShadow: 'none',
                    '&:focus': {
                        outline: 'none', 
                        boxShadow: 'none!important', 
                        border: 'none'
                    }
                }}
                type="search"
                placeholder="Buscar articulo"
                aria-label="Search"
                id={"searchArticle"}
                value={search}
                onChange={handleSearch}
            />
        </>
    )
}
