import React, { useContext, useEffect, useState } from 'react'
import { GridCards } from '../Section/GridCards'
import { Filters } from '../Components/Filters/Filters'
import { NotFound } from '../Components/NotFound'
import { useLocation, useParams } from 'react-router-dom'
import { StoreContextMain } from '../Context/StoreContextMain'
import queryString from 'query-string'
 
export const Secciones = () => {

    const {search, filtersContext } = useContext(StoreContextMain)
    
    const {filters, search : searchParam} = useParams()

    const [buildFilters, setBuildFilters] = useState({})
    const [searchParamBuild, setSearchParamBuild] = useState({})


    useEffect(() => {
        try {

            if (filters !== '') {
                setBuildFilters(applyFilters(filters))
            }

            if (searchParam === 'name') {
                setSearchParamBuild({[searchParam]:search})
            }

            if (typeof filtersContext === 'object') {
                const keys = Object.keys(filtersContext)
                keys.map( (key) => {
                    setBuildFilters( (filter) => ({...filter, [key]: filtersContext[key]}))
                } )
            }


        } catch (error) {
            console.log(error);
        }

        return () => {
            setBuildFilters('')
            setSearchParamBuild('')
        }

    }, [filters, search, searchParam, filtersContext])

  
    return (
        <div className='row '>
            <aside className='col-3 col-md-2'>
                <Filters />
            </aside>
            <div className='col-9 col-md-10'>
                <div className='mx-3 me-3'>
                    {
                        buildFilters.length === 0 && searchParamBuild.length === 0 && (
                            <NotFound 
                                icon="🔍" 
                                title="No se encontraron resultados" 
                                message="Intenta realizar otra búsqueda o vuelve más tarde." 
                            />
                        )
                    }
                    <GridCards
                        url={'products/'}
                        params = {buildFilters || searchParamBuild}
                        pagination={true}
                    />
                </div>
            </div>
        </div>
    )
}


const applyFilters = (filters) => {
    
        if (filters?.length <= 0) {
            return {}
        }

        switch (filters) {
            case 'hombre':
                return {"category__name":"Hombre"} // esto es el sexo
            case 'mujer':
                return {"category__name":"Mujer"} // esto es el sexo
            case 'infantil':
                return {"category__name":"Infantil"} // este es la edad
            case 'niños':
                return {"category__name":"Infantil"} // este es la edad
            case 'adultos':
                return {"category__name":"Adultos"} // este es la edad
            case 'jovenes':
                return {"category__name":"Jóvenes"} // este es la edad
            case 'tercera-edad':
                return {"category__name":"Ansianos"} // este es la edad
            case 'unisex':
                return {"category__name":"Unisex"}
            case 'sombreros':
                return {"category__name":"Sombrero"}
            case 'camisas':
                return {"category__name":"Camisa"}
            case 'pantalones':
                return {"category__name":"Pantalón"}
            case 'calzado':
                return {"category__name":"Calzado"}
            case 'accesorios':
                return {"category__name":"Accesorios"}
            case 'chaquetas':
                return {"category__name":"Chaqueta"}
            case 'shorts':
                return {"category__name":"Short"}
            case 'vestidos':
                return {"category__name":"Vestidos"}
            case 'faldas':
                return {"category__name":"Falda"}
            case 'blusas':
                return {"category__name":"Blusa"}
            case 'playeras':
                return {"category__name":"Playeras"}
            case 'zapatos':
                return {"category__name":"Zapatos"}
            case 'botas':
                return {"category__name":"Botas"}
            case 'sandalias':
                return {"category__name":"Sandalias"}
            case 'tenis':
                return {"category__name":"Tenis"}
            case 'tacones':
                return {"category__name":"Tacones"}
            case 'bolsos':
                return {"category__name":"Bolsos"}
            case 'gorras':
                return {"category__name":"Gorras"}
            case 'bufandas':
                return {"category__name":"Bufandas"}
            case 'pañuelos':
                return {"category__name":"Pañuelos"}
            case 'lentes':
                return {"category__name":"Lentes"}
            case 'joyeria':
                return {"category__name":"Joyería"}
            case 'relojes':
                return {"category__name":"Relojes"}
            case 'carteras':
                return {"category__name":"Carteras"}
            case 'cinturones':
                return {"category__name":"Cinturones"}
            case 'mochilas':
                return {"category__name":"Mochilas"}
            case 'calcetines':
                return {"category__name":"Calcetines"}
            case 'guantes':
                return {"category__name":"Guantes"}
            case 'ropa-interior':
                return {"category__name":"Ropa interior"}
            case 'promociones':
                return {"discounted":true}
            case 'novedades':
                return {"new":true}
            case 'ofertas':
                return {"offer":true}
            case 'mas-vendidos':
                return {"best_seller":true}
            default:
                return {}
        }
}


