import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, A11y, Mousewheel, Autoplay, EffectCoverflow } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/mousewheel';
import 'swiper/css/effect-coverflow';
// import 'swiper/css/scrollbar';

export const Banner = ({contentArray = [], slidesPerView = 'auto', breakpoints={}, className = '', autoplay = false, enableCoverFlow = false}) => {

    const addCoverFlowEffect = () => {
        if (enableCoverFlow) {
            return {
                coverflowEffect: {
                    rotate: 60,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                },
                effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true
            }
        }

        return {}
    }

    
    return (
        <Swiper
            className = {className}
            modules={[Navigation, A11y, Autoplay, EffectCoverflow]}
            spaceBetween={15}
            autoplay={ autoplay ? { delay: 9500, disableOnInteraction: false } : false }
            slidesPerView={slidesPerView}

            {...addCoverFlowEffect()}

            breakpoints={breakpoints}
            onSlideChange={() => {}}
            onSwiper={(swiper) => {}}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            // touchEventsTarget={'container'}
            // touchMoveStopPropagation={true}
            // preventClicksPropagation={true}
            // cssMode={true}
        >
            {
                contentArray?.map( (value, index) => (
                    <SwiperSlide onClick={()=>{}} key={index}>
                        {value}
                    </SwiperSlide>
                ) )
            }
      </Swiper>
    )
}
