import { PayPalButtons } from "@paypal/react-paypal-js";
import { requestPost } from "../../../../Helpers";


export const PayPalCustom = ({price}) => {
  
    const displayOnly = ["vaultable"];

    function createOrder() {

        const formData = new FormData();

        formData.append("price", price);
        formData.append("currency", "MXN");
        formData.append("intent", "capture");
        formData.append("description", "Compra de productos");
        formData.append("user", "1");
        formData.append("status", "1");
        formData.append("payment_method", "1");
        formData.append("order_id", "1");

        return requestPost("sales/", formData)
                .then((data) => {
                    const datos = JSON.parse(data)
                    if ( typeof(datos) == "object" ) {
                        return datos
                    } else {
                        return false
                    }
                })

        // return fetch("/my-server/create-paypal-order", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     // use the "body" param to optionally pass additional order information
        //     // like product ids and quantities
        //     body: JSON.stringify({
        //         cart: [
        //             {
        //                 id: "YOUR_PRODUCT_ID",
        //                 quantity: "YOUR_PRODUCT_QUANTITY",
        //                 price: ""
        //             },
        //         ],
        //     }),
        // })
        //     .then((response) => response.json())
        //     .then((order) => order.id);
    }

    function onApprove(data) {
        return fetch("/my-server/capture-paypal-order", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderID: data.orderID
          })
        })
        .then((response) => response.json())
        .then((orderData) => {
              const name = orderData.payer.name.given_name;
              alert(`Transaction completed by ${name}`);
        });

    }
  
    

    return (
        <>
            <PayPalButtons
                style={{ 
                    shape: "pill",
                    layout: "vertical",
                    color: "silver",
                    borderRadius: 10,
                }}
                displayOnly={displayOnly}
                createOrder={createOrder}
                onApprove={onApprove}
                
            />

        </>
    )
}
