import React, { useEffect, useMemo, useState } from 'react'
import { Banner } from '../Components/Banners'
import { useRequestGet } from '../Hooks';
import { NavLink } from 'react-router-dom';

export const BannersImgs = () => {

  const {data} =  useRequestGet('banners/', {"tipo":"BA"});

  const [banners, setBanners] = useState([]);

  useMemo(() => {
    try {
      if (data) {
        setBanners( () => data?.results )
      }
    } catch (error) {
      console.log(error);
    }

    return () => {
      setBanners([])
    }

  }, [data])

  return (
    <>
        <Banner
            contentArray={
              banners?.map( (banner) => (
                  <NavLink
                    to={banner.url} 
                    key={banner.uuid}
                  >
                    {card(banner.image, '', 'center')}
                  </NavLink>
              ))
            }
            slidesPerView={1}
            autoplay={true}
            enableCoverFlow={true}
            
        />
    </>
  )
}

const card = (img, title, position, handle = () => {}) => {
  // position = position === "center" ? "center" : position === "left" ? "start" : "end";

  return (
    <button className="card m-0 p-0 d-flex justify-content-center rounded rounded-5 " style={{ width: "calc(100vw - 25px)", height: "calc(100vh - 95px)" }} onClick={()=>handle}>
      <img src={img} className="card-img w-100" style={{ borderRadius: '50px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: "scroll" }} alt="..." />
      {/* <div className="card-img-overlay h-100">
        <div
          className={`d-flex h-100 mx-5 justify-content-${position} align-items-center`}
        >
          {title && (
            <h5
              className="card-title text-uppercase text-bg-light opacity-75 p-5 rounded rounded-5"
              style={{ fontSize: "5rem" }}
            >
              <span className="text-warning" style={{ fontWeight: "bold" }}>
                {title}
              </span>
            </h5>
          )}
        </div>
      </div> */}
    </button>
  );
};

