import React from 'react'
import { Route, Routes } from "react-router-dom";
import {
    DetailsPayment,
    PersonalInformation,
    ConfirmPurchase,
    PurchaseDetail,
  } from "../View";
import { PaymentProvider } from '../Context/PaymentProvider';
  
export const RouterPayment = () => {

    return (
        <PaymentProvider>
            <Routes>
                <Route path="/details-payment" element={<DetailsPayment />} />
                <Route path="/personal-information" element={<PersonalInformation />} />
                <Route path="/purchase-detail" element={<PurchaseDetail />} />
                <Route path="/confirm-purchase" element={<ConfirmPurchase />} />
            </Routes>
        </PaymentProvider>
    )
}
