import { useContext } from "react";
import { NavLink } from "react-router-dom";
import {Menu} from "./Menu";
import LogoClothes from "../Assets/img/logoRopa.png";
import { SearchEngine } from "../Components";
import { Car } from "../MyCars";
import { AuthContext, GoUser, useChekingAuth } from "../Auth";
import { useScreenSize } from "../Hooks";

export const Header = () => {

  const { width } = useScreenSize();

  const {logged} = useContext(AuthContext);
  
  return (
    <nav className="right-0 navbar navbar-expand-lg bg-body fixed-top" style={{ zIndex: 100 }}>
      <div className="container-fluid">

        <NavLink className="p-0 m-0 navbar-brand" to="/" style={{ textDecoration: 'none', color: 'black' }}>
          <img className={'logoClothes'} height={40} src={LogoClothes} alt={''} />
          {width > 768 ? '   Cabaña Rodeo Country' : ''}
        </NavLink>

        <SearchEngine />
        
        <GoUser />

        {
          logged && <Car />
        }

        <Menu logged={logged} />

      </div>
    </nav>
  );
};
