import React, { useContext, useEffect, useState } from 'react'
import { Card } from './Components'
import { useForm } from '../../Hooks';
import { AuthContext } from '../../Auth';
import { jwtDecode } from 'jwt-decode';
import { requestPost } from '../../Helpers';

export const AddPaymentMethod = () => {

    const {formState, onInputChange} = useForm({
        typeCard: '',
        numberCard: '',
        nameCard: '',
        month: '',
        year: '',
        cvvCard: ''
    });

    useEffect(() => {
        try {
            console.log(formState)
        } catch (error) {
            console.log(error);
        }
    }, [formState])

    const handleAddCard = (e) => {
        e.preventDefault();

        const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

        const decode = jwtDecode(token);

        const formData = new FormData();
        formData.append('user_id', decode?.user_id);
        formData.append('type_card', formState.typeCard);
        formData.append('card_number', formState.numberCard);
        formData.append('card_holder', formState.nameCard);
        formData.append('month', formState.month);
        formData.append('year', formState.year);
        formData.append('cvv', formState.cvvCard);
        
        console.log('formData', formData);

        requestPost('cards/', formData)
            .then((data) => {
                console.log('data', data);
            })
            .catch((error) => {
                console.log(error);
            })

    }

    return (
        <div className='container'>
            <h3 className='text-center mt-3'>Agregar método de pago</h3>
            <div className='row mt-2'>
                <div className='col-12 col-md-6 order-md-0'>
                    <form >
                        <div className='mb-3'>
                            <label htmlFor='typeCard' className='form-label'>Tipo de tarjeta</label>
                            <select className='form-select' id='typeCard' name='typeCard' value={formState.typeCard} onChange={onInputChange}>
                                <option value='Visa'>Visa</option>
                                <option value='MasterCard'>MasterCard</option>
                                <option value='American Express'>American Express</option>
                            </select>
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='numberCard' className='form-label'>Número de tarjeta</label>
                            <input type='text' className='form-control' id='numberCard' name='numberCard' value={formState.numberCard} onChange={onInputChange} />
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='nameCard' className='form-label'>Nombre del titular</label>
                            <input type='text' className='form-control' id='nameCard' name='nameCard' value={formState.nameCard} onChange={onInputChange} />
                        </div>
                        
                        <div className='d-flex gap-3 justify-content-between'>
                            <div className='mb-3'>
                                <label htmlFor='month' className='form-label'>Mes</label>
                                <input type='text' className='form-control' id='month' name='month' value={formState.month} onChange={onInputChange} />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='year' className='form-label'>Año</label>
                                <input type='text' className='form-control' id='year' name='year' value={formState.year} onChange={onInputChange} />
                            </div>
                        </div>

                        <div className='mb-3 w-25'>
                            <label htmlFor='cvvCard' className='form-label'>CVV</label>
                            <input type='text' className='form-control' id='cvvCard' name='cvvCard' value={formState.cvvCard} onChange={onInputChange} />
                        </div>

                        <div className='d-flex justify-content-end'>
                            <button type='submit' className='btn btn-primary' onClick={handleAddCard}>Agregar tarjeta</button>
                        </div>
                    </form>
                </div>
                <div className='col-12 col-md-6 order-0'>
                    <div className='d-flex m-auto justify-content-center align-items-center h-100' >
                        <Card
                            typeCard={formState.typeCard || 'Visa'}
                            numberCard={formState.numberCard || '****************'}
                            cardHolder={formState.nameCard || 'Nombre del titular'}
                            month={formState.month || 'MM'}
                            year={formState.year || 'YY'}
                            cvvCard={formState.cvvCard || '***'}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}
