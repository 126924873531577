import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../Auth";
import { useForm } from "../../Hooks";
import { requestGet, requestPost } from "../../Helpers";
import { CardAddress } from "./Components";
import { useParams } from "react-router-dom";
import { alertError, alertToast } from "../../Helpers/Alerts";

export const AddressForm = () => {

  const {id} = useParams()

  const { formState, onInputChange, onResetForm } = useForm({
    name: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    instructions: ""
  });

  useEffect( () => {
    try {
      if (id !== '' && id !== undefined && id !== null) {
        getDataByDefault(id)
      }
      
    } catch (e) {
      console.log('error', e)
    }

  }, [id] )

  const getDataByDefault = (id) => {
    requestGet(`shipping_addresses/`, {"id": id})
    .then( (data)=> {
      data = data?.results[0]
      onResetForm({
        name: data?.name,
        address: data?.address,
        city: data?.city,
        state: data?.state,
        zipcode: data?.zipcode,
        phone: data?.phone,
        instructions: data?.instructions
      })
    })
  }

  const handleAddAddress = (e) => {
    e.preventDefault();

    const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

    const decoded = jwtDecode(token);

    const formData = new FormData();
    formData.append("user", decoded?.user_id);
    formData.append("name", formState.name);
    formData.append("address", formState.address);
    formData.append("city", formState.city);
    formData.append("zipcode", formState.zipcode);
    formData.append("phone", formState.phone);
    formData.append("instructions", formState.instructions)

    requestPost("shipping_addresses/", formData)
      .then((data) => {
        if (data) {
          alertToast("Dirección agregada correctamente", "success");
          onResetForm();
        }
      })
      .catch((error) => {
        alertError("Error al agregar dirección", "Por favor, intenta de nuevo");
      });
  };

  return (
    <div className="container">
      <h3 className="text-center mt-3"> {id ? 'Actualizar dirección' : 'Agregar dirección'} </h3>
      <div className="row mt-2">
        <div className="col-12 col-md-6 order-md-0">
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nombre personal
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formState.name}
                onChange={onInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Dirección
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formState.address}
                onChange={onInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                Ciudad
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                value={formState.city}
                onChange={onInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="state" className="form-label">
                Estado
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
                value={formState.state}
                onChange={onInputChange}
              />
            </div>
            <div className=" row row-cols-md-2">
              <div className="mb-3">
                <label htmlFor="zipcode" className="form-label">
                  Código postal
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="zipcode"
                  name="zipcode"
                  value={formState.zipcode}
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Teléfono
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formState.phone}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="instructions" className="form-label">
                  Información adicional
                </label>
                <textarea
                  className="form-control"
                  id="instructions"
                  name="instructions"
                  onChange={onInputChange}
                >{formState.instructions}</textarea>
            </div>
            <button className="btn btn-primary" onClick={handleAddAddress}>
              Agregar dirección
            </button>
          </form>
        </div>

        <div className="col-12 col-md-6 order-0">
          <div className=" d-flex flex-wrap m-auto w-100 h-100 justify-content-center align-content-center">
            <CardAddress
              name={formState.name || "Nombre"}
              address={formState.address || "Dirección"}
              city={formState.city || "Ciudad"}
              state={formState.state || "Estado"}
              zipcode={formState.zipcode || "Código postal"}
              phone={formState.phone || "Teléfono"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
