import {useContext} from 'react'
import { StoreContextMain } from '../../Context';
import { Card } from '../../Components';
import { deleteOrderItem } from '../../Components/Cards/helpers';

export const Cars = () => {
    const { store, setStore } = useContext(StoreContextMain);

    const handleDelete = (id) => {

        deleteOrderItem(id)
            .then((data) => {
                console.log("Eliminado correctamente");
                console.log("Eliminado: ", data);
                const newStore = store.filter((item) => item.id !== id);
                setStore(newStore);
            })
    }
    

    return (
        <div className="card shadow-none p-0">
            <div className="card-header">
                <h4>Mi carrito </h4>
            </div>
            <div className="card-body">
                <div className='row'>
                    {
                        store.map((item, index) => (
                            <div className="col" key={index}>
                                <button className='btn-trash-my-card position-absolute m-4' style={{ }} onClick={() => handleDelete(item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                    </svg>
                                </button>
                                <div className="card-body">
                                    <Card
                                        key={index}
                                        id={item.id}
                                        title={item.title}
                                        image={item.image}
                                        price={item.price}
                                        color={item.color}
                                        size={item.size}
                                        amount={item.amount}
                                        amount_max={item.amount_max}
                                        enableFeacture={true}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
