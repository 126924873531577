import { CiCircleCheck } from "react-icons/ci";

/**
 * Este componente se encarga de mostrar los colores de los productos.
 * @param {object} colors
 * @param {string} active
 * @param {function} handleColors
 * @returns {JSX.Element}
 * @example 
 * <Colors colors={colors} active={active} handleColors={handleColors} />
 */

export const Colors = ({colors, active, handleColors}) => {
    
    const chooseColor = (color) => {
        color = color.toLowerCase()
        switch (color) {
            case 'rojo': return { color: '#ff0000' };
            case 'azul': return { color: '#0000ff' };
            case 'verde': return { color: '#00ff00' };
            case 'blanco': return { color: '#f4f4f4' };
            case 'negro': return { color: '#000000' };
            case 'cafe': return { color: '#A18262' };
            case 'naranja': return { color: '#FFA500' };
            case 'gris': return { color: '#9B9B9B' };
            default: return { color: '#ffffff' };
        }
    }

    return (
        <button
            className=' justify-content-center align-items-center'
            style={{
                backgroundColor: chooseColor(colors).color,
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                margin: '0 5px',
                border: active === colors ? '1px solid' : 'none',
                boxShadow: active === colors ? '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' : 'none'
            }}
            onClick={handleColors}
        >
            {
                active === colors && (
                    <CiCircleCheck className='fs-2' color={chooseColor(colors).color.substring(0, 3).concat('EDFA')}  />
                )
            }
        </button>
    )
}
