import React, { useContext, useEffect, useState } from 'react'
import { StoreContextMain } from '../../Context/StoreContextMain'
import { CardFavorite } from '../../Components/Cards/CardFavorite'
import { requestGet, requestPost } from '../../Helpers'
import { AuthContext } from '../../Auth'
import { jwtDecode } from "jwt-decode";


export const Favorites = () => {

    const { myWish, setMyWish } = useContext(StoreContextMain)

    const [stateFav, setStateFav] = useState(false)

    const getWishList = (user) => {

        requestGet(`wishlists/`, {'user': user})
            .then((data) => {
                setStateFav(data)
            });
    }

    const addWishListItem = (id) => {
        const formData = new FormData();
        formData.append("product", id);
        formData.append("wishlist", 1);

        requestPost("wishlist_items/", formData).then((data) => {
            console.log("data", data);
        });
    }

    useEffect(() => {
        try {
            const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

            const decoded = jwtDecode(token)
    
            getWishList(decoded?.user_id)

        } catch (error) {
            console.log(error);
        }
        
    }, [])

    const sendListData = (id) => {
        setMyWish(id)
    }

    return (
        <>
            {
                stateFav?.results?.map((item, index) => {

                    return (
                        <button key={index} className='btn p-0 mb-3' onClick={ () => sendListData(item?.uuid)} >
                            <div className='card p-0 shadow-none '>
                                <div className='card-header border-0'>
                                    {item.name}
                                </div>
                            </div>
                        </button>
                    )
                } )
            }
        </>
    )
}
