import React, { useEffect, useState } from 'react'
import { CustomGrid } from '../Components/CustomGrid'
import { Card } from '../Components/Cards/Card'
import { useRequestGet } from '../Hooks';
import { useLocation } from 'react-router-dom';
import { Pagination } from '../Components/Pagination/Pagination';
import { SpinnerLoading } from '../Components/SpinnerLoading';
import { NotFound } from '../Components/NotFound';
import { requestGet } from '../Helpers';


export const GridCards = ({url = '', params = {}, pagination = false}) => {

    const location = useLocation();

    // Obtiene la página actual de la URL o establece 1 si no existe
    const query = new URLSearchParams(location.search);
    const initialPage = Number(query.get('currentpage')) || 1;

    const [products, setProducts] = useState([]);
    const [page, setPagination] = useState(initialPage)

    useEffect(() => {
        
        if (url !== '' && page !== 0 && Object.keys(params).length !== 0) {
            getProducts(page, params);
        } else {
            return setProducts([]);
        }

        return () => {
            setProducts([])
        }

    }, [params, page])

    const getProducts = (page, params) => {
        params = {...params, "page": page}
        
        requestGet(url, params)
            .then( (data) => {
                console.log('getProducts: ',data);
                setProducts(data)
            })
    }

    return (
        <div>
            {
                products?.length === 0 
                ? (
                    <SpinnerLoading />
                ) : products?.results?.length === 0 ? (
                    <NotFound />
                ): (
                    <>
                        <CustomGrid
                            arrayContent={
                                products?.results?.map((product) => (
                                    <Card
                                        key={product.id}
                                        id={product.uuid}
                                        image={product.image?.image}
                                        title={product.name}
                                        description={product.description}
                                        price={product.price}
                                    />
                                ))
                            }
                        />

                        {
                            pagination && products?.count > 16 && (
                                <div className='d-flex justify-content-center'>
                                    <Pagination
                                        totalItems={products?.count}
                                        itemsByRequest={products?.results?.length}
                                        setPrevious={setPagination}
                                        setNext={setPagination}
                                    />
                                </div>
                            )

                        }
                    </>
                )
            }
        </div>
    )
}
