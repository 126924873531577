import React from 'react'

export const SpinnerLoading = () => {
  
    return (
        <div className=' d-flex h-100 w-100 justify-content-center align-content-center'>
            <div className="spinner-border m-5" role="status">
                <span className="visually-hidden">Cargando...</span>
            </div>
        </div>
    )
}
