import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Auth";
import { requestGet } from "../../Helpers";
import { jwtDecode } from "jwt-decode";
import { CardAddress } from "./Components";
import { useNavigate } from "react-router-dom";

export const Address = () => {
  const navigate = useNavigate();

  const [address, setAddress] = useState([]);

  useEffect(() => {
    try {
      const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

      const decoded = jwtDecode(token);

      requestGet(`shipping_addresses/`, {"user": decoded?.user_id})
        .then(
          (data) => {
            setAddress(() => data?.results);
          }
        );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleNavigateToAddAddress = () => {
    navigate("/profile/address/add");
  };

  return (
    <div className="container">
      <h2 className="text-start text-primary"> Direcciones de envío </h2>

      <div className="row">
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            onClick={handleNavigateToAddAddress}
          >
            Agregar dirección
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-4">
          {address?.map((item) => (
            <CardAddress
              key={item.id}
              name={item.name}
              address={item.address}
              city={item.city}
              state={item.state}
              zipcode={item.zipcode}
              phone={item.phone}
              updated_at={item.updated_at}
              ruta={`/profile/address/${item.id}/update`}
              deleteAddres={true}
            />
          ))}
        </div>
      </div>

      <div className="row"></div>
    </div>
  );
};
