import React, { useEffect, useState } from 'react'

export const Count = ({cantidad_max, count, setCount}) => {

    // const [count, setCount] = useState(0)
    const [disabled, setDisabled] = useState(true)

    useEffect( ()=> {
        setDisabled(count <= 0 ? true: false)
    }, [count] )

    const addCont = () => {
        if (count + 1 > cantidad_max) {
            setCount(cantidad_max)
        } else {
            setCount(count + 1)
        }
    }

    const substractCont = () => {
        if (count <= 0) return setCount(0)
        setCount(count - 1)
    }
    
    return (
        <>
            <div className='d-flex mt-3 rounded rounded-5 border'>
                <button className='btn btn-secondary rounded-end rounded-5' disabled={ disabled } onClick={substractCont}> - </button>
                <input type='text' value={count} readOnly className='border-0 form-control text-center' />
                <button className='btn btn-secondary rounded-start rounded-5' onClick={addCont}> + </button>
            </div>
            <i className=' text-muted mt-0 pt-0' style={{ fontSize: '14px' }}>
                Productos en existencia: {cantidad_max > 10 ? '+10' : cantidad_max}
            </i>
        </>
    )
}
