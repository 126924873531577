import React, { useEffect, useState } from 'react'
import { CardOrder } from '../../Components'
import { useNavigate, useParams } from 'react-router-dom'
import { requestGet } from '../../Helpers'
import { useChekingAuth } from '../../Auth'

/**
 * Componente para visualizar los productos comprados
 * En este componente se visualizan los productos comprados por el usuario,
 * se puede ver el detalle de la compra y el estado de la misma,
 * además de poder realizar acciones como cancelar la compra,
 * solicitar ver o imprimir el recibo de la compra,
 * ver el estado de la compra, entre otras.
 * @returns 
 */

export const ViewShopping = () => {

    const navigate = useNavigate()

    const {order_id, sale_id} = useParams()

    const { logged } = useChekingAuth();

    const [shopping, setShopping] = useState([])

    useEffect(() => {

        try {
            if (logged) {
                getSaleItems()
            }
        } catch (error) {
            console.log(error)
        }

    }, [logged])


    const handleViewPackageTracking = () => {
        navigate('/profile/my_shopping/view/package_tracking/' + sale_id)
    }

    const handleViewRecibo = () => {
        navigate('/profile/my_shopping/view/ticket/' + sale_id)
    }

    const getSaleItems = async () => {
        if (order_id === null || order_id === undefined) {
            return setShopping([])
          };
          
        await requestGet(`order_items/`, {"order": order_id})
        .then((data) => {
            data = data?.results.map((item) => {
            // se obtiene la información del producto
                const product = {
                    id: item?.category?.product_uuid,
                    title: item?.category?.product_name,
                    price: item?.price,
                    image: item?.category?.product_image !== null ? item?.category?.product_image[0]?.image : '',
                    color: '',
                    size: '',
                    amount: item?.quantity,
                    amount_max: item?.category?.stock
                }

                // se obtiene el color y la talla del producto
                item?.category?.category?.forEach( category => {

                if (category.category === "Colores") {
                    product.color = category?.name;
                } else if (category.category === "Tallas") {
                    product.size = category?.name;
                }

            })
            
                return product
            } );

            setShopping(data ?? []);
        
        })

    }

    return (
        <div className='row'>

            <div className='col-12'>

                <div className='d-flex flex-column justify-content-around m-3 mb-5'>
                    <h2 className='text-center'>Mi compra</h2>
                    
                    <div className=''>
                        <p className=''>ID de la compra: {order_id}</p>
                        <p className=''>ID de la venta: {sale_id}</p>
                        <p className=''>Descripción: </p>
                    </div>

                </div>

                <div className='row'>
                    <div className='col-12 col-md-8'>
                        {
                            shopping?.map((item, index) => {
                                console.log('item', item)
                                return (
                                    <div>
                                        <CardOrder
                                            key={index}
                                            id={item.id}
                                            title={item.title}
                                            image={item.image}
                                            price={item.price}
                                            color={item.color}
                                            size={item.size}
                                            amount={item.amount}
                                            amount_max={item.amount_max}
                                            enableFeacture={true}
                                        />   
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='col-12 col-md-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h3 className='text-center'>Detalle de la compra</h3>
                                <p className='text-center'>Total: $</p>
                                <p className='text-center'>Fecha de compra: </p>
                                <p className='text-center'>Estado de la compra: </p>
                                <p className='text-center'>Método de pago: </p>
                            </div>
                        </div>
                        <hr />
                        <div className='card'>
                            <div className='card-body'>
                                <h3 className='text-center'>Acciones</h3>
                                <div className='d-flex justify-content-around gap-2 flex-wrap'>
                                    <button className='btn btn-primary'>Cancelar compra</button>
                                    <button onClick={handleViewPackageTracking} className='btn btn-primary'>Ver seguimiento</button>
                                    <button onClick={handleViewRecibo} className='btn btn-primary'>Ver recibo</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
