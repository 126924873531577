import { useEffect, useReducer, useState } from 'react';
import { types } from '../Types/types';
import { AuthContext } from './AuthContext';
import { authReducer } from './authReducer';
import { useChekingAuth } from '../Hooks/useChekingAuth';

const initialState = {
    logged: false,
    user: {},
}


export const AuthProvider = ({children}) => {

    const [authState, dispatch] = useReducer(authReducer, initialState);
    const [isRegister, setIsRegister] = useState(false);
    const { logged, user, isLoading } = useChekingAuth();

    useEffect(() => {

        if (logged) {
            dispatch({
                type: types.login,
                payload: user
            })
        }

    }, [logged])

    const login = ( nombres, correo = '', is_superuser = false, is_staff = false, is_active = true, token) => {

        const user = {
            // id: id,
            nombres,
            correo,
            is_superuser,
            is_staff,
            is_active,
            token
        }

        const action = {
            type: types.login,
            payload: user
        }

        localStorage.setItem('user_store_ecommerce', JSON.stringify(token));

        dispatch(action);

        return true;   
    }

    const logout = () => {
        localStorage.removeItem('user_store_ecommerce');
        
        dispatch({
            type: types.logout
        });
    }

    return (
        <AuthContext.Provider
            value={{
                ...authState,
                login,
                logout,
                isRegister,
                setIsRegister,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
