
import React, { useContext, useState } from 'react'
import { StoreContextMain } from '../../../Context/StoreContextMain'
import { CiShoppingCart } from 'react-icons/ci'
import { alertDialog, alertError, alertToast } from '../../../Helpers/Alerts'
import { AuthContext, useChekingAuth } from '../../../Auth'
import { useNavigate } from 'react-router-dom'
import { createOrder, addOrderItem, getOrder } from '../helpers'
import { jwtDecode } from 'jwt-decode'

/**
 * Este componente es un botón que se encarga de agregar un producto al carrito de compras.
 * @param {string} id
 * @param {string} title
 * @param {string} description
 * @param {number} price
 * @param {string} image
 * @param {string} text_aditional
 * @returns {JSX.Element}
 */

export const BtnAddCar = ({ id, title, description, price, image, text_aditional = '' }) => {

  const { store, setStore, selectedCharacteristics : feature } = useContext(StoreContextMain)
  
  const { logged } = useChekingAuth();

  const navigate = useNavigate()

  const [stateAdd, setStateAdd] = useState(false);

  const addCar = () => {
    // Validaciones de los campos requeridos
    if(!logged) return alertDialog('Primero necesitas iniciar sesión o registrarte', 'Ir', 'Cancelar').then(res => res ? navigate('/my-account') : false)

    if (id === null || id === '' || id === undefined) return alertError('Producto no disponible', 'No se ha encontrado el producto disponible');

    let color = feature.color ?? null
    let size = feature.size ?? null
    let amount = feature.amount ?? null
    let amount_max = feature.amount_max ?? null

    if (color === null || color === '') return alertError('Seleciona el color', 'No se ha selecionado el color');
    if (size === null || size === '') return alertError('Seleciona el tamaño', 'No se ha seleccionado el tamaño');
    if (amount === null || amount === 0) return alertError('Seleciona la cantidad', 'No se ha selecionado la cantidad');


    // Crear la orden y agregar el producto
    createOrderAndAddItem()
  }

  // Crear la orden y agregar el producto
  const createOrderAndAddItem = async () => {
    // Obtener el token del usuario
    const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

    const decoded = jwtDecode(token)
    let order = 0

    // Obtener la orden del usuario
    await getOrder(decoded?.user_id)
      .then(async (data) => {
  
        // Si la orden existe, la asigna a la variable order, de lo contrario crea una nueva orden
        if (data?.results?.length > 0) {
          order = data.results[0]
        } else {
          order = 0
        }
      } )
      .catch((error) => {
        console.log(error)
      } )

      // Si no se ha podido obtener la orden, crea una nueva orden
      if (order === 0) {
        await createOrder(decoded?.user_id)
        .then((data) => {
          order = data
        })
        .catch((error) => {
          console.log(error)
        })
      }

      // Agregar el producto a la orden
      await addOrderItem(order.uuid, feature?.productCategory?.uuid)
      .then((data) => {
        if (!data) {
          alertError('Ha ocurrido un error', 'No se ha podido agregar el producto al carrito')
        } else {
          // Crear el objeto del producto
          let item = {
            id: data?.category?.product_uuid,
            title: data?.category?.product_name,
            price: data?.price,
            image: data?.category?.product_image !== null ? data?.category?.product_image[0]?.image : '',
            color: '',
            size: '',
            amount: data?.quantity,
            amount_max: data?.category?.stock
          }

          // se obtiene el color y la talla del producto
          data?.category?.category?.forEach( category => {
  
            if (category.category === "Colores") {
              item.color = category?.name;
            } else if (category.category === "Tallas") {
              item.size = category?.name;
            }

          })

          // Agregar el producto al carrito
          setStore([...store, item])
          // Cambiar el estado del botón
          setStateAdd(!stateAdd)
          // Mostrar una alerta
          alertToast('Producto agregado al carrito', 'success', 'start')
        }
      } )
      .catch((error) => {
        console.log(error)
        alertError('Ha ocurrido un error', 'No se ha podido agregar el producto al carrito')
      })

  }

  return (
    <button
        className={`car  ${text_aditional && 'd-flex btn btn-primary '}`}
        onClick={addCar}
    >
        <CiShoppingCart
          size={'2rem'}
          className={text_aditional && 'me-2'}
          style={{ color: stateAdd ? 'green' : 'var(--cafe-oscuro)'}}
        />
        {text_aditional}
    </button>
  )
}

