import React, { useContext, useEffect, useState } from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google';
import { requestPost } from '../../Helpers'
import { useForm } from '../../Hooks';
import { alertCustomHtml, alertError } from '../../Helpers/Alerts';
import { AuthContext } from '../Context';
import {CelebrationAnimation} from '../../Components/Animations/CelebrationAnimation';
import { s } from 'framer-motion/client';


export const Register = () => {

    const {login, setIsRegister} = useContext(AuthContext);

    const navigate = useNavigate()

    const {formState, onInputChange} = useForm({
        email: '',
        password1: '',
        password2: '',
        terms: false
    });

    const [password, setPassword] = useState('');

    useEffect(() => {
        try {

            if (formState.password1 !== formState.password2) {
                setPassword('Las contraseñas no coinciden');
            } else {
                setPassword('');
            }

        } catch (error) {
            console.log(error);
        }

    } , [formState.password1, formState.password2, formState.terms]) 

    const onRegister = () => {
        
        if (formState.password1 !== formState.password2) {
            alertError('Las contraseñas no coinciden', 'Por favor, verifique sus contraseñas');
            return;
        }

        if (formState.terms === false) {
            alertError('Debe aceptar los términos y condiciones', 'Por favor, acepte los términos y condiciones');
            return;
        }

        const formData = new FormData();
        formData.append('email', formState.email);
        formData.append('password', formState.password1);
        formData.append('terms', formState.terms);

        requestPost('register/', formData)
            .then((resp) => {

                if (typeof resp === 'object') {
                    
                    const {name, email, is_superuser, is_active, is_staff} = resp.user;
                    const {access} = resp;
                    
                    if (login(name, email, is_superuser, is_active, is_staff, access)) {
                        setIsRegister(true);
                        navigate('/', { replace: true });
                    }

                } else {
                    alertError('Usuario o contraseña incorrectos', 'Por favor, verifique sus credenciales')
                }
            }
        )
    }

    const onRegisterGoogle = (credentialResponse) => {

        if (formState.terms === false) {
            alertError('Debe aceptar los términos y condiciones', 'Por favor, acepte los términos y condiciones');
            return;
        }

        const formData = new FormData();
        formData.append('token', credentialResponse?.credential);
        formData.append('terms', formState.terms);

        requestPost('login-google/', formData)
            .then((resp) => {

                if (typeof resp === 'object') {

                    const {name, email, is_superuser, is_active, is_staff} = resp.user;
                    const {access} = resp;

                    if (login(name, email, is_superuser, is_active, is_staff, access)) {
                        setIsRegister(true);
                        navigate('/', { replace: true });
                    }

                } else {
                    alertError('Usuario o contraseña incorrectos', 'Por favor, verifique sus credenciales')
                }
            }
        )
    }
  
    return (
        <div className="d-flex justify-content-center h-100 py-4">

            <div className="">

                <h1>Registrarse</h1>
                <p>¿Ya tiene una cuenta? Por favor <NavLink to="/login">inicie sesión</NavLink>.</p>
            
                <form className=''>
                    <input type="hidden" name="csrfmiddlewaretoken" value="HNz8U7oOdGxf1rmS2bJUsNRn6kJDDQYJG1nYRTZWxWFwpEJqrRWeX4Jdlkl5SrKn"/>
                    
                    <div id="div_id_email" className="mb-3"> 
                        <label htmlFor="id_email" className="form-label requiredField">
                            Correo electrónico
                            <span className="asteriskField">*</span> 
                        </label>
                        <input
                            onChange={onInputChange}
                            type="email" 
                            name="email"
                            placeholder="Dirección de correo electrónico" 
                            autoComplete="email" 
                            maxLength="320" 
                            className="textinput form-control" 
                            required 
                            id="id_email"
                        />
                    </div>
                    <div id="div_id_password1" className="mb-3"> 
                        <label htmlFor="id_password1" className="form-label requiredField">
                            Contraseña
                            <span className="asteriskField">*</span>
                        </label> 
                        <input
                            type="password" 
                            onChange={onInputChange}
                            name="password1" 
                            placeholder="Contraseña" 
                            autoComplete="new-password" 
                            className="passwordinput form-control" 
                            required 
                            id="id_password1"
                        /> 
                        <div id="id_password1_helptext" className="form-text">
                            <ul>
                                <li>
                                    Su contraseña no puede ser muy similar a su otra información personal.
                                </li>
                                <li>
                                    Su contraseña no puede ser muy corta. Debe contener al menos 8 caracteres.
                                </li>
                                <li>
                                    Su contraseña no puede ser una contraseña de uso común.
                                </li>
                                <li>
                                    Su contraseña no puede ser completamente numérica.
                                </li>
                            </ul>
                        </div> 
                    </div>
                    <div id="div_id_password2" className="mb-3"> 
                        <label htmlFor="id_password2" className="form-label requiredField">
                            Contraseña (de nuevo)
                            <span className="asteriskField">*</span> 
                        </label>
                        <input
                            type="password" 
                            name="password2" 
                            onChange={onInputChange}
                            placeholder="Contraseña (de nuevo)" 
                            autoComplete="new-password" 
                            className="passwordinput form-control" 
                            required 
                            id="id_password2"    
                        />
                    </div>

                    <div className="mb-3 bg-danger bg-opacity-75 m-2">
                        {password && <p className='text-center text-white p-1' >{password}</p>}
                    </div>

                    {/* Terminos y condiciones y politicas de provacidad */}
                    <div className="form-check mb-3">
                        <input
                            className="form-check-input" 
                            type="checkbox" 
                            name="terms" 
                            required
                            onChange={onInputChange}
                            value={formState.terms}
                            id="id_terms"
                        />
                        <label className="form-check-label" htmlFor="id_terms">
                            Acepto los <NavLink to="/terminos-condiciones">términos y condiciones</NavLink> 
                            y la <NavLink to="/politicas-privacidad">política de privacidad</NavLink>.
                        </label>
                    </div>

                    <div className='mb-3 d-flex flex-column justify-content-center' >
                        <p>¿Quieres registrarte por gmail? Por favor, da clic al google.</p>
                        <div className='d-flex justify-content-center'>    
                            <GoogleLogin
                                onSuccess={ credentialResponse => {
                                    
                                    onRegisterGoogle(credentialResponse);
                                }}
                                onError={() => {
                                    alertError('Error al registrarse con Google', 'Por favor, intente de nuevo');
                                }}
                            />
                        </div>
                    </div>
                    
                    <div className='d-flex justify-content-center' >
                        <button type="button" className="btn btn-primary" onClick={onRegister}>
                            Registrarse
                        </button>
                    </div>

                    <p className='text-center mt-3'>¿Ya tiene una cuenta? Por favor <NavLink className='btn-link' to="/login">inicie sesión</NavLink>.</p>
                    
                </form>
                
            </div>
        </div>
    )
}
