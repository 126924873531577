import React, { useContext, useState } from "react";
import { FaHeart } from "react-icons/fa";
import { StoreContextMain } from "../../../Context/StoreContextMain";
import { AuthContext } from "../../../Auth";
import { createWishList, addWishListItem, getWishList, deleteItem } from "../helpers";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";

/**
 * Este componente es un botón que se encarga de agregar un producto a la lista de deseos.
 * @param {string} id
 * @param {string} title
 * @param {string} description
 * @param {number} price
 * @param {string} image
 * @returns {JSX.Element}
 */

export const BtnFav = ({ id, title, description, price, image }) => {

  const { myWish, setMyWish } = useContext(StoreContextMain);
  const [stateFav, setStateFav] = useState(false);

  
  const addFav = () => {
    const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

    const decoded = jwtDecode(token)

    setMyWish([...myWish, { id, title, description, price, image }]);

    const wishList = getWishList(decoded?.user_id);
    
    wishList.then((data) => {
      console.log("data", data);

      if (data?.results?.length === 0) {
        createWishList(decoded?.user_id)
          .then((data) => {
            console.log("data", data);
            addWishListItem(id, data?.value)
          })
          .catch((error) => {
            console.log("error", error);
          });
      }

      if (stateFav) {
        setStateFav(false);
        return deleteItem(id);
      }

      if (data?.results?.length > 0) {
        Swal.fire({
          title: "Selecciona una lista de deseos",
          input: "select",
          inputOptions: data?.results.map((item) => item.name),
          showCancelButton: true,
          confirmButtonText: "Agregar",
          showLoaderOnConfirm: true,
          preConfirm: (name) => {
            let wishlistUUID = data?.results[name]?.uuid;
            setStateFav(true);
            return addWishListItem(id, wishlistUUID);
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }

    } );
    

    return

    // createWishList(decoded?.user_id)
    //   .then((data) => {
    //     console.log("data", data);
    //     addWishListItem(id, data?.value)
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });

  };

  return (
    <button
      className="fav"
      style={{ backgroundColor: 'transparent' }}
      onClick={addFav}
    >
      <FaHeart 
        size={"1.8rem"} 
        style={{ color: stateFav ? "#FF0000" : "gray" }}
      />
    </button>
  );
};
