import React, { useContext, useEffect, useState } from 'react'
import { useForm, useRequestGet } from '../../Hooks'
import { StoreContextMain } from '../../Context/StoreContextMain'

/**
 * Este componente se encarga de mostrar los filtros de los productos.
 * en base a las categorias y subcategorias de los productos que vienen de la API.
 * @returns {JSX.Element}
 */

export const Filters = () => {

    const { filtersContext, setFilterContext } = useContext(StoreContextMain)

    const [selectedValues, setSelectedValues] = useState({});
    const [filters, setFilters] = useState(null)
    const [enableUpdate, setEnableUpdate] = useState(false)

    const {data} = useRequestGet('category/')

    const { formState, onInputChange } = useForm({})

    useEffect(() => {
        if (data) {
            setFilters( data )
        }
    }, [data])

    useEffect(() => {
        setFilterContext(formState)
    }, [formState, setFilterContext, enableUpdate])

    const handleCheckValue = (e) => {
        const {name, checked, value} = e.target;
        setSelectedValues((prev) => ({
            ...prev,
            [name]:checked
        }) )

        if (checked) {
            onInputChange(e)
            setEnableUpdate(false)
        } else {
            delete formState[name]
            console.log(formState)
            setEnableUpdate(true)
        }
    }

    return (
        <div className='d-flex flex-column w-100 m-auto' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {
                filters?.map((item, index) => {

                    if (item?.category_item?.length <= 0 || item?.category_item === null) {
                        return null
                    }

                    return (
                        <form key={index}>
                            <h5> {item.name} </h5>
                            <ul>
                            {
                                item?.category_item?.map((sub, index) => (
                                    <label key={index} className='checkbox__label'>
                                        <span className="checkbox__container">
                                            <input 
                                                className='checkbox' 
                                                type='checkbox' 
                                                name={sub.name} 
                                                value={sub.uuid} 
                                                checked={selectedValues[sub.name] || false}
                                                onChange={handleCheckValue}    
                                            />
                                            <span className='checkbox__label--text '> {sub.name} </span>
                                        </span>
                                    </label>
                                ))
                            }
                            </ul>
                        </form>
                    )
                })
            }
        </div>
    )
}
