import { useState, useEffect } from "react";
import IMGLOGO from "../../Assets/img/logoRopa.png";

import '../../Assets/Styles/tracking.css';

export const PackageTracking = () => {
    
        const [tracking, setTracking] = useState([])
    
    
    
        return (
            <div className='container'>
    
                <h1 className='text-center'>Rastreo de paquete</h1>
    
                <div className='row'>

                    <div className='col-12 col-md-6'>
                        <section className="root">
                            <figure>
                                <img className="img-fluid" src={IMGLOGO} alt=""/>
                                <figcaption>
                                    <h4>Some Jewellery Name</h4>
                                    <h6>Category</h6>
                                    <h2>₹ 23,456</h2>
                                </figcaption>
                            </figure>
                            <div className="order-track">
                                <div className="order-track-step">
                                    <div className="order-track-status">
                                        <span className="order-track-status-dot"></span>
                                        <span className="order-track-status-line"></span>
                                    </div>
                                    <div className="order-track-text">
                                        <p className="order-track-text-stat">Order Received</p>
                                        <span className="order-track-text-sub">21st November, 2019</span>
                                    </div>
                                </div>
                                <div className="order-track-step">
                                    <div className="order-track-status">
                                        <span className="order-track-status-dot"></span>
                                        <span className="order-track-status-line"></span>
                                    </div>
                                    <div className="order-track-text">
                                        <p className="order-track-text-stat">Order Processed</p>
                                        <span className="order-track-text-sub">21st November, 2019</span>
                                    </div>
                                </div>
                                <div className="order-track-step">
                                    <div className="order-track-status">
                                        <span className="order-track-status-dot"></span>
                                        <span className="order-track-status-line"></span>
                                    </div>
                                    <div className="order-track-text">
                                        <p className="order-track-text-stat">Manufracturing In Progress</p>
                                        <span className="order-track-text-sub">21st November, 2019</span>
                                    </div>
                                </div>
                                <div className="order-track-step">
                                    <div className="order-track-status">
                                        <span className="order-track-status-dot"></span>
                                        <span className="order-track-status-line"></span>
                                    </div>
                                    <div className="order-track-text">
                                        <p className="order-track-text-stat">Order Dispatched</p>
                                        <span className="order-track-text-sub">21st November, 2019</span>
                                    </div>
                                </div>
                                <div className="order-track-step">
                                    <div className="order-track-status">
                                        <span className="order-track-status-dot"></span>
                                        <span className="order-track-status-line"></span>
                                    </div>
                                    <div className="order-track-text">
                                        <p className="order-track-text-stat">Order Deliverd</p>
                                        <span className="order-track-text-sub">21st November, 2019</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='col-12 col-md-6'>

                        <div>
                            <h2>Estado del paquete</h2>
                            <p>El paquete se encuentra en camino</p>
                            <p>Fecha de entrega estimada: 21 de noviembre de 2021</p>
                        </div>

                        <div>
                            <h2>Detalles del paquete</h2>
                            <p>Paquetería: FedEx</p>
                            <p>Guía de rastreo: 123456789</p>    
                        </div>

                        <div>
                            <h2>Estamos trabajando para que tengas el paquete lo antes posible </h2>
                            <img className="img-fluid" src={IMGLOGO} alt=""/>
                        </div>

                    </div>
                    
                </div>
                    
            </div>
    )
}

