
import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { googleLogout } from '@react-oauth/google';
import {AiOutlineMenu} from "react-icons/ai";
import LogoClothes from "../Assets/img/logoRopa.png";
import { OffCanvas } from "../Components/OffCanvas/OffCanvas";
import { AuthContext } from "../Auth";


export const Menu = ({logged}) => {

    const {logout} = useContext(AuthContext);

    const navigate = useNavigate();

    const handleLogout = () => {
        try {
            logout();
            googleLogout();
            navigate('/', {replace: true, state: {message: 'Sesión cerrada'}});
            window.location.reload();
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <> 
            <OffCanvas
                iconMain={<AiOutlineMenu className={'fs-2'} />}
                title={
                    <>
                        <img className={'logoClothes'} height={40} src={LogoClothes} alt={''} />
                        Cabaña Rodeo Country
                    </>
                }
                footer={
                    <>
                        {
                            logged && (
                                <div className={'d-flex'}>
                                    <button onClick={handleLogout} className="w-100 btn btn-logout btn-danger">
                                        Cerrar sesión 
                                        <svg fill="#ffffff" width="20px" height="20px" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" id="memory-logout"><path d="M17 1V2H18V6H17V5H16V3H6V19H16V17H17V16H18V20H17V21H5V20H4V2H5V1H17M13 6H15V7H16V8H17V9H18V10H19V12H18V13H17V14H16V15H15V16H13V14H14V13H15V12H8V10H15V9H14V8H13V6Z" /></svg>
                                    </button>
                                </div>
                            )
                        }
                    </>
                }
            >
                <div className="list-group list-group-flush menu-offcanvas-body">
                    <NavLink to="/seccion/ofertas" className="list-group-item list-group-item-action">Promociones</NavLink>
                    <NavLink to="/seccion/mujer" className="list-group-item list-group-item-action">Mujer</NavLink>
                    <NavLink to="/seccion/hombre" className="list-group-item list-group-item-action">Hombre</NavLink>
                    <NavLink to="/seccion/niños" className="list-group-item list-group-item-action">Niños</NavLink>

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header m-0 p-0" id="flush-headingOne">
                                <button className="accordion-button collapsed border-0 " type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                        aria-expanded="false" aria-controls="flush-collapseOne">
                                    Accesorios
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse m-0 p-0 collapse"
                                    aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body pt-0 m-0">
                                    <div className="list-group list-group-flush mt-0 pt-0">
                                        <NavLink to="/seccion/carteras" className="list-group-item list-group-item-action"> Carteras </NavLink>
                                        <NavLink to="/seccion/sombreros" className="list-group-item list-group-item-action"> Sombreros </NavLink>
                                        <NavLink to="/seccion/cinturones" className="list-group-item list-group-item-action"> Cinturón </NavLink>
                                        <NavLink to="/seccion/mas" className="list-group-item list-group-item-action"> Más </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            logged && (
                                <div className="accordion-item">
                                    <h2 className="accordion-header m-0 p-0" id="flush-headingProfile">
                                        <button className="accordion-button collapsed border-0 " type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseProfile"
                                                aria-expanded="false" aria-controls="flush-collapseProfile"
                                            >
                                            Mi perfil
                                        </button>
                                    </h2>
                                    <div id="flush-collapseProfile" className="accordion-collapse m-0 p-0 collapse"
                                            aria-labelledby="flush-headingProfile" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body pt-0 m-0">
                                            <div className="list-group list-group-flush mt-0 pt-0">
                                                <NavLink to="/profile/view" className="list-group-item list-group-item-action"> Perfil </NavLink>
                                                {/* <NavLink to="/profile/settings" className="list-group-item list-group-item-action"> Configuración </NavLink> */}
                                                <NavLink to="/profile/my_shopping" className="list-group-item list-group-item-action"> Mis compras </NavLink>
                                                <NavLink to="/profile/wishlist" className="list-group-item list-group-item-action"> Favoritos </NavLink>
                                                <NavLink to="/profile/cart" className="list-group-item list-group-item-action"> Carrito </NavLink>
                                                <NavLink to="/profile/address" className="list-group-item list-group-item-action"> Direcciones </NavLink>
                                                <NavLink to="/profile/payment" className="list-group-item list-group-item-action"> Métodos de pago </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </OffCanvas>
        </>
    )
}