import React from 'react'

export const CardDetailPayment = ({ title = '', totalProducts = 0, productsList = [], iva = 0, total = 0 }) => {

    const getPercentageOfTotal = (iva) => {
      return (Math.floor(total * iva) / 100) ?? 0;
    }

    const calculateTotal = (total, iva) => {
      return total + getPercentageOfTotal(iva)
    }
  
    return (
        <>
            <div className="card " style={{ boxShadow: 'none' }}>
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <p className="card-text">Total de productos: {totalProducts}</p>
              <ul>
                {productsList?.map((item, index) => (
                  <li key={index}>{item.title}</li>
                ))}
              </ul>
              <p className="card-text">
                IVA del {iva}%: ${getPercentageOfTotal(iva)}
              </p>
              <p className="card-text">
                Total a pagar: ${calculateTotal(total, iva)}
              </p>
            </div>
          </div>
        </>
    )
}
