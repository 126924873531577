import { Route, Routes } from "react-router-dom";
import { useChekingAuth } from "../Auth";
import { RouterAuth } from "../Auth/Router/RouterAuth";
import { RouterPayment } from "../Payment/Router/RouterPayment";
import { RoutesProfile } from "../Profile";
import { Home, ViewProduct, Secciones, TerminosCondiciones, PoliticasPrivacidad} from "../Pages";
import { PublicRoutes } from "./PublicRoutes";
import { PrivateRoutes } from "./PrivateRoutes";

export const RoutesRoot = () => {
  const { logged } = useChekingAuth();

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/seccion" element={<Secciones />} />
        <Route path="/seccion/:filters" element={<Secciones />} />
        <Route path="/seccion_search/:search" element={<Secciones />} />
        <Route path="/view-product/:id" element={<ViewProduct />} />
        <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
        <Route path="/politicas-privacidad" element={<PoliticasPrivacidad />} />

        {
          logged ? (
            <>
              <Route path="profile/*" element={<RoutesProfile />} />
              <Route path="payment/*" element={<RouterPayment />} />
            </>
          ) : (
            <Route path="/*" element={<RouterAuth />} />
          )
        }

        {/* <Route 
            path='/*'
            element={
                <PublicRoutes logged={logged}>
                    <RouterAuth />
                </PublicRoutes>
            }
        />

        <Route
            path='profile/*'
            element={
                <PrivateRoutes logged={logged}>
                    <RoutesProfile />
                </PrivateRoutes>
            }
        /> */}
      </Routes>
    </>
  );
};
