
import React from 'react'
import { Recibo } from '../../Components/Recibo'

export const ViewRecibo = ({address, productsList, payment}) => {
  
    return (
        <div>
            <h1>Recibo</h1>

            <div className='row'>
                <button className='btn btn-primary'>Imprimir</button>
            </div>

            <div>
                <Recibo
                    user={address?.name}
                    address={address?.address}
                    city={address?.city}
                    state={address?.state}
                    phone={address?.phone}
                    instructions={address?.instructions}
                    productsList={productsList}
                    total={payment?.total}
                    discount={payment?.discount}
                    iva={payment?.iva}
                    costo_envio={payment?.costo_envio}
                />
            </div>

        </div>
    )
}
