import {useState, useEffect, useContext} from "react";
import { Colors } from "./Colors";
import { Count } from "./Count";
import { Sizes } from "./Sizes";
import { StoreContextMain } from "../../Context/StoreContextMain";

/**
 * Este componente se encarga de mostrar las características del producto.
 * @param {object} category
 * @returns {JSX.Element}
 * @example
 * <Characteristics category={category} /> 
 */

export const Characteristics = ({category}) => {

    const { setSelectedCharacteristics } = useContext(StoreContextMain)

    const [colors, setColors] = useState(null);
    const [sizes, setSizes] = useState(null);
    const [cantidad, setCantidad] = useState(0);

    const [attributes, setAttributes] = useState(null);

    const [activeColor, setActiveColor] = useState(null)
    const [activeSize, setActiveSize] = useState(null)
    const [count, setCount] = useState(0)
    const [getCategorys, setCategorys] = useState([])

    useEffect(()=> {
        try {
            let colores = new Set()
            let tallas = new Set()
            // Filtrar las categorias de los productos
            let categories = category[0]?.category.map( (item) => item.category )
            // obtener la longitud de las categorias
            let lenCategories = categories.length;

            setCategorys(categories)
    
            setAttributes(category)
    
            category?.forEach(item => {
                // Filtrar los colores y tallas de los productos
                item?.category.forEach( cat => {
                    if (cat.category === "Colores") {
                        colores.add( cat.name )
                    }
                    if (cat.category === "Tallas") {
                        tallas.add( cat.name )
                    }
                })
            })
    
            setColors(Array.from(colores))
            setSizes(Array.from(tallas))
    
            return () => {
                setColors(null)
                setSizes(null)
            }
        } catch (e) {
            console.log(e)
        }

    }, [category])

    useEffect ( ()=> {
        let productCategory = {}
        // Si se han seleccionado los dos atributos, se obtiene el producto disponible
        if (getCategorys.length === 2) {
            if(activeColor && activeSize) {
                productCategory = getAvailableProducts()
            }
        } else if (getCategorys.length === 1) {
            
        }
        //Los atributos que se han seleccionado, se guardan el el contexto, para ser usados en el carrito
        setSelectedCharacteristics({
            color:activeColor,
            size: activeSize,
            amount: count,
            amount_max: cantidad,
            productCategory: productCategory
        })

    }, [activeColor, activeSize, count, setSelectedCharacteristics, getCategorys] )

    /*
    * Función para actualizar los colores disponibles en base a la talla seleccionada
    * @param {string} size
    * @returns {void}
    * Guarda en el estado los colores disponibles en base a la talla seleccionada
    */
    const updateColorsBySize = (size) => {
        try {
            let colores = new Set()

            if (size) {
                // Filtrar los colores en base a la talla seleccionada y guardarlos en el estado
                const filterColorBySize = category.filter( (item) => {
                    return item.category.find( (found) => found.name === size )
                } ).map( (color) => color.category.find((cat) => cat.category === "Colores")); 

                filterColorBySize.flat().forEach(item => {
                    if (item.category === "Colores") {
                        colores.add( item.name )
                    }
                })

                // setColors(filterColorBySize.flat());

            } else {
                // Reset colors to all colors if no size is selected
                category.forEach(item => {
                    item?.category.forEach(cat => {
                        if (cat.category === 'Colores')  colores.add(cat.name)
                    })
                })
                // setColors(colores);
              }

              setColors(Array.from(colores));
        } catch (e) {

        }
    }

    /*
    * Función para actualizar las tallas disponibles en base al color seleccionado
    * @param {string} color
    * @returns {void}
    * Guarda en el estado las tallas disponibles en base al color seleccionado
    * */
    const updateSizesByColor = (color) => {
        try {
            let tallas = new Set()

            if (color) {
                // Filtrar las tallas en base al color seleccionado y guardarlas en el estado
                const filtredSizes = category.filter( (item) => {
                    return item.category.find( (found) => found.name === color )
                } ).map( (size) => size.category.find(cat => cat.category === 'Tallas') );
                
                filtredSizes.flat().forEach(item => {
                    if (item.category === "Tallas") {
                        tallas.add( item.name )
                    }
                })
                // setSizes(Array.from(tallas))
            } else {
                // Reset sizes to all sizes if no color is selected
                category.forEach(item => {
                    item?.category.forEach(cat => {
                        if (cat.category === 'Tallas')  tallas.add(cat.name)
                    })
                })
                // setSizes(Array.from(tallas))
            }

            setSizes(Array.from(tallas))

        } catch (e) {

        }
    }

    const getAvailableProducts = () => {

        // Filtrar el array attributes según la talla y color seleccionados
        const availableProduct = attributes?.find((item) => {
            const hasSelectedColor = item.category.some(cat => cat.category === "Colores" && cat.name === activeColor);
            const hasSelectedSize = item.category.some(cat => cat.category === "Tallas" && cat.name === activeSize);

            return hasSelectedColor && hasSelectedSize;
        });
        setCantidad(availableProduct?.stock)
        console.log(availableProduct)
        return availableProduct;
    }

    // Handle color selection
    const handleColorChange = (color) => {
        if (activeColor === color) {
            setActiveColor(null)
            updateSizesByColor(null)
        } else {
            setActiveColor(color)
            updateSizesByColor(color)
        }
    }

    // Handle size selection
    const handleSizeChange = (size) => {
        if (activeSize === size) {
            setActiveSize(null);
            updateColorsBySize(null); 
        } else {
            setActiveSize(size);
            updateColorsBySize(size); 
        }
    };

    return (
        <div className="d-flex flex-column">

            <div className="mb-3">
                <h6 > Color </h6>
                <div className="d-flex flex-wrap">
                    {
                        colors?.map((color, index) => {
                            return (
                                <Colors
                                    key={index}
                                    colors={color}
                                    active={activeColor}
                                    handleColors={()=>handleColorChange(color)}
                                    // setActiveColor={setActiveColor}
                                />
                            );
                        })
                    }
                </div>
            </div>

            <div className="mb-3">
                <h6> Talla </h6>
                <div className="d-flex flex-wrap">
                    {
                        sizes?.map((size, index) => {
                            return (
                                <Sizes
                                    key={index}
                                    name={ size }
                                    active={activeSize}
                                    handleSizes={()=>handleSizeChange(size)}
                                />
                            );
                        })
                    } 
                </div>
            </div>
            
            <div className="mb-3 w-50">
                <h5> Cantidad </h5>
                <Count 
                    cantidad_max={cantidad} 
                    count={count} 
                    setCount={setCount}
                />
            </div>
        </div>
    );
};
