import React, { useEffect, useState } from "react";
import { useScreenSize } from "../../../Hooks";

export const FilterSearch = () => {

  const { width } = useScreenSize();


  return (
    <>
      <div 
        className={'custom-search d-none d-md-block ' }
      >
        <select className={""} >
          <option value="0">Todas las categorias</option>
          <option value="1">Mujer</option>
          <option value="2">Hombre</option>
          <option value="3">Niños</option>
        </select>
      </div>
    </>
  );
};


